import { autoinject, signalBindings } from 'aurelia-framework';
import { ApiService } from './api-service';
import { UserDocumentService } from './user-document-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from './toast-service';
import { CurrencyService } from './currency-service';
import { debug, adminPanelUrl, websiteShortCode } from 'environment';
import { CustomerService } from './customer-service';
import { BlacklistService } from './blacklist-service';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';
import { ReferralService } from './referral-service';
import { Helper } from 'resources/extensions/helper';
import countryToCurrency from 'country-to-currency';
import { PaymentMethodThreshold, ThresholdOperationType, VerificationType } from './models/purchase-flow/paymentMethodWebsite';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import jwtDecode from 'jwt-decode';
import { JwtPayloadExtended } from './models/JwtPayloadExtended';
import { Currency } from './models/purchase-flow/exchange';
import { ScriptService } from './script-service';
import { SiteSettingService } from './site-setting-service';
import { HttpClient, json } from 'aurelia-fetch-client';

const CURRENCY_KEY = 'currency';
const EMAIL_IN_REVIEW_KEY = 'email_in_review';
const PREVIOUS_CURRENCY_KEY = 'previous_currency';
const REFERRAL_LINK_KEY = 'referral_link';
const REFERRER_LINK_KEY = 'refferer_link';
const ADMIN_VIEW = 'admin_view';
const PURCHASED = 'purchased';
const CART_KEY = 'cart';
const GAME_CHARACTER = 'game_character_names';
const CURRENT_BLOGS_PAGE_VIEW = 'current_blogs_page_view';

@autoinject()
export class SessionService {
    isAuthenticated = false;
    currentUser = null;
    geolocation;
    userProxy;
    private intercomTimer: NodeJS.Timer;
    private TOKEN_KEY = 'jwt_token';
    CURRENCY_OPTIONS = [];
    userCart;
    userBlacklist;
    canOpenLiveChat = true;
    intervalAttempts;
    triggeredIntercomScript;
    isRefreshing: boolean;
    refreshTokenPromise: Promise<string> | null;

    constructor(
        private apiService: ApiService,
        private eventAggregator: EventAggregator,
        private userDocumentService: UserDocumentService,
        private toastService: ToastService,
        private currencyService: CurrencyService,
        private customerService: CustomerService,
        private blacklistService: BlacklistService,
        private thousandSeparatorValueConverter: ThousandSeparatorValueConverter,
        private referralService: ReferralService,
        private helper: Helper,
        private camelCaseValueConverter: CamelCaseValueConverter,
        private scriptService: ScriptService,
        private siteSettingService: SiteSettingService,
        private http: HttpClient
    ) { }

    async login(data) {
        const formattedData = {
            email: data.email,
            password: data.password,
            token: data.token,
            websiteShortCode: websiteShortCode(),
            jwtToken: data.jwtToken,
            signInOption: data.signInOption,
            fingerprint: await this.siteSettingService.getDeviceInfoForUser(),
            baseToken: data.baseToken,
            skipChecks: data.skipChecks,
            validationCode: data.validationCode
        };

        const { ipv4Address, ipv6Address } = await this.helper.fetchIPsForCustomer() ?? {};
        Object.assign(formattedData, { ipv4Address, ipv6Address });

        const response = await this.apiService.doPost('', formattedData);

        if (response) {
            if (!response.resetPassword) {
                if (response.userBlacklist?.length > 0) {
                    this.userBlacklist = response.userBlacklist;
                    const blackListResponse = await this.verifyIsUserInBlackList(null, true);
                    if (blackListResponse) {
                        return;
                    }
                }
                this.saveToken(response.token);
                this.isAuthenticated = true;
            }
            this.helper.handleGtagEvent('login', null, null, null, null, data?.signInOption);
            return response;
        }
    }

    async getProfile(forceRefresh = false) {
        if (await this.isTokenValid()) {
            if (this.currentUser && !forceRefresh) {
                return this.currentUser;
            }
            return this.fetchProfileData(forceRefresh);
        }
    }

    async refreshProfile() {
        if (await this.isTokenValid()) {
            return this.fetchProfileData(true);
        }
    }

    async fetchProfileData(forceFetch) {
        this.currentUser = await this.helper.fetchData(this.apiService, 'profile', 'fetchProfileData', forceFetch);
        if (this.currentUser?.isDeleted) {
            await this.toastService.showToast('Account deleted', 'As a result you have been logged out, please Sign up again to access your account or contact Support.', 'info');
            await this.logout();
        }
        if (this.currentUser?.userBlacklist?.length > 0) {
            this.userBlacklist = this.currentUser.userBlacklist;
            const blackListResponse = await this.verifyIsUserInBlackList(null, true);
            if (blackListResponse) {
                await this.logout();
            }
        }
        return this.currentUser;
    }

    async verifyIsUserInBlackList(email, skipEvent) {
        this.userBlacklist = skipEvent ? this.userBlacklist : await this.blacklistService.getBlacklistByUserEmail(email);
        let message = 'BLlvl4';
        for (const row of this.userBlacklist) {
            if (row.level > 2) {
                this.closeLivechat();
            }
            if (row.level === 4) {
                message += row.category === 1 ? 'E' : row.category === 2 ? 'P' : row.category === 3 ? 'IP' : row.category === 4 ? 'C' : 'R';
                this.toastService.showToast('There was an unexpected error. Please contact customer support at support@divicasales.com. Ref:' + message + '.', 'Error', 'error');
                return true;
            }
        }
        return false;
    }

    closeLivechat() {
        this.canOpenLiveChat = false;
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }

    async initializeLiveChat() {
        let intercomSettings = {};
        if (this.currentUser) {
            intercomSettings = {
                email: debug() ? `STAGING-${this.currentUser?.email?.replace('@', '_ds@')}` : `${this.currentUser?.email?.replace('@', '_ds@')}`,
                // eslint-disable-next-line camelcase
                user_id: debug() ? `S${this.currentUser?.id}` : this.currentUser?.id,
                // eslint-disable-next-line camelcase
                user_hash: this.currentUser?.liveChatHash,
                name: `${this.currentUser?.firstName} ${this.currentUser?.lastName}`,
                // eslint-disable-next-line camelcase
                customer_URL: `${adminPanelUrl()}customers/${this.currentUser?.id}`,
                // eslint-disable-next-line camelcase
                website_FROM: 'DivicaSales'
            };
        } else {
            intercomSettings = {
                // eslint-disable-next-line camelcase
                website_FROM: 'DivicaSales'
            };
        }

        const globalIntercomSettings = {
            // eslint-disable-next-line camelcase
            api_base: 'https://api-iam.intercom.io',
            // eslint-disable-next-line camelcase
            app_id: 'dqgl5no3',
            ...intercomSettings
        };

        window.intercomSettings = globalIntercomSettings;

        if (this.currentUser) {
            this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser.email);
            if (this.userBlacklist?.find(x => x.level === 3 || x.level === 4)) {
                if (window.Intercom) {
                    window.Intercom('shutdown');
                }
                return;
            }
        }

        if (!this.triggeredIntercomScript) this.scriptService.injectIntercomScript();

        if (this.intercomTimer) return;

        this.intervalAttempts = 0;
        this.intercomTimer = setInterval(async () => {
            if (window.Intercom && this.canOpenLiveChat) {
                window.Intercom('boot', globalIntercomSettings);

                const urlParams = new URLSearchParams(window.location.search);
                const openLivechat = urlParams.get('openLivechat');
                if (openLivechat === 'true') {
                    window.Intercom('showNewMessage', '');
                }
                clearInterval(this.intercomTimer);
                this.intercomTimer = null;
            } else if (this.intervalAttempts > 10) {
                clearInterval(this.intercomTimer);
                this.intercomTimer = null;
            }
            this.intervalAttempts++;
        }, 1000);
    }

    getGameCharacter(game) {
        const gameCharacters = window.localStorage[GAME_CHARACTER];
        if (gameCharacters) {
            const json = JSON.parse(gameCharacters);
            if (json[game?.toString()]) {
                return json[game?.toString()];
            }
        }
        return null;
    }

    saveGameCharacter(game, characterName) {
        const gameCharacters = window.localStorage[GAME_CHARACTER];
        if (gameCharacters) {
            let games = JSON.parse(gameCharacters);
            if (games.length === 0) {
                games = {};
            }
            games[game] = characterName;
            window.localStorage[GAME_CHARACTER] = JSON.stringify(games);
        } else {
            const games = {};
            games[game] = characterName;
            window.localStorage[GAME_CHARACTER] = JSON.stringify(games);
        }
    }

    getPlatformLinkCookie(name) {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || undefined;
    }

    async verifyPassword(password) {
        if (await this.isTokenValid()) {
            const verifyPassword = await this.apiService.doPost('VerifyPassword', password);
            return verifyPassword;
        }
    }

    removeCookie(name) {
        if (Array.isArray(name)) {
            name.forEach(x => document.cookie = `${x}= ; expires=0; path=/`);
            return;
        }
        document.cookie = `${name}= ; expires=0; path=/`;
    }

    async logout() {
        try {
            await this.apiService.doDelete('Logout');
            this.eventAggregator.publish('untrack-user', { userId: this.currentUser.id });
            this.saveBlogsCurrentPageView(1);
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            if (!this.destroyToken) return;
            this.destroyToken();
            this.currentUser = null;
            this.userCart = null;
            this.isAuthenticated = false;
            this.eventAggregator.publish('user-updated', { forDelete: true, redirect: true });
            await this.getCart();
            await this.destroyCart();
            this.helper.clearServiceQueueState('fetchProfileData');
        }
    }

    clearSession() {
        this.destroyToken();
        this.currentUser = null;
        this.userCart = null;
        this.isAuthenticated = false;
        this.eventAggregator.publish('user-updated', { forDelete: true, redirect: false });
    }

    saveToken(token) {
        window.localStorage[this.TOKEN_KEY] = token;
    }

    private getStoredToken() {
        return window.localStorage[this.TOKEN_KEY] as string | null;
    }

    async getToken() {
        const token = this.getStoredToken();
        const isValid = await this.isTokenValid(false, token, false);

        if (!isValid && token) {
            try {
                return await this.refreshAccessToken();
            } catch (e) {
                console.error(e);
                this.destroyToken();
                return null;
            }
        }

        return token;
    }

    refreshAccessToken() {
        if (this.isRefreshing) {
            // If a refresh is already in progress, wait for it to complete
            return this.refreshTokenPromise;
        }

        this.isRefreshing = true;

        // Create a promise to manage the refresh state
        this.refreshTokenPromise = new Promise((resolve, reject) => {
            const token = this.getStoredToken();

            if (!token) {
                reject('No access token to refresh');
            }

            this.http.fetch('RefreshToken', {
                method: 'POST',
                body: json({ token }),
                headers: {
                    'X-Skip-Interceptor': 'true'
                }
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Failed to refresh token');
                }

                return response.json();
            }).then(data => {
                this.saveToken(data.token);

                this.isRefreshing = false;
                this.refreshTokenPromise = null;

                resolve(data.token);
            }).catch(err => {
                this.isRefreshing = false;
                this.refreshTokenPromise = null;

                reject(err);
            });
        });

        return this.refreshTokenPromise;
    }

    destroyToken() {
        window.localStorage.removeItem(this.TOKEN_KEY);
    }

    async getUserProxy() {
        return this.userProxy;
    }

    async getCountry() {
        return this.geolocation?.countryCode;
    }

    async getCity() {
        return this.geolocation?.city;
    }

    async isCountryValidForCartPage() {
        const invalidCountries = [
            'AL',
            'BA',
            'ME',
            'XK',
            'MK',
            'RS',
            'BY',
            'MM',
            'CF',
            'IR',
            'RU',
            'KP',
            'CU',
            'CD',
            'ET',
            'IQ',
            'LB',
            'LY',
            'SY'
        ];
        return this.currentUser?.roles?.includes('ViewAdminPanel') || !invalidCountries.includes(this.geolocation?.countryCode);
    }

    async getState() {
        return this.geolocation?.regionName;
    }

    saveAdminView(bool) {
        window.localStorage[ADMIN_VIEW] = bool;
        this.eventAggregator.publish('admin-view-updated', { bool: bool });
    }

    getAdminView() {
        return (window.localStorage[ADMIN_VIEW] === 'true');
    }

    async checkRolesForPanelAccess() {
        try {
            const token = await this.getToken();
            if (!token) return;
            return (jwtDecode(token) as JwtPayloadExtended)?.admin;
        } catch {
            return;
        }
    }

    async validateAuthorizationCode(authorizationCode: string, clientId: string, validationCode?: string) {
        return await this.apiService.doPost('AuthorizeCode', { authorizationCode, clientId, validationCode }) as { token: string } | null;
    }

    async isTokenValid(ignoreExpire = false, token: string = null, fetchToken = true) {
        if (fetchToken) {
            token ??= await this.getToken();
        }

        let expired: boolean;
        let requiresIpAuthorization: boolean;
        try {
            const validToken = jwtDecode<JwtPayloadExtended>(token);
            expired = Date.now() >= (validToken?.exp * 1000);
            requiresIpAuthorization = Boolean(validToken?.ria);
        } catch {
            return false;
        }
        return token && token !== '' && token !== undefined && token !== 'undefined' && token !== 'null' && (!expired || ignoreExpire) && !requiresIpAuthorization;
    }

    isLoggedIn() {
        return Boolean(this.currentUser);
    }

    saveEmailInReview(bool) {
        window.localStorage[EMAIL_IN_REVIEW_KEY] = bool;
    }

    getEmailInReview() {
        const emailInReview = window.localStorage[EMAIL_IN_REVIEW_KEY];
        if (emailInReview) {
            return JSON.parse(emailInReview);
        }
    }

    async getIdVerificationInReview(id) {
        const idVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 1);
        if (idVerificationInReview) {
            return idVerificationInReview;
        }
    }

    async getAddressVerificationInReview(id) {
        const addressVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 2);
        if (addressVerificationInReview) {
            return addressVerificationInReview;
        }
    }

    async getSelfieVerificationInReview(id) {
        const selfieVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 3);
        if (selfieVerificationInReview) {
            return selfieVerificationInReview;
        }
    }

    deleteByUserIdAndCategoryId(userId, categoryId) {
        this.userDocumentService.deleteByUserIdAndCategoryId(userId, categoryId);
    }

    async getCurrencyOptions() {
        const currencies: Currency[] = await this.currencyService.getActiveCurrenciesByWebsite();
        this.CURRENCY_OPTIONS = [];
        if (currencies?.length) {
            for (const currency of currencies) {
                this.CURRENCY_OPTIONS.push({
                    id: currency.id,
                    value: currency.code,
                    text: currency.code,
                    displayName: currency.description,
                    shortName: currency.shortName,
                    icon: currency.imagePath,
                    currencySymbol: currency.symbol,
                    ...currency
                });
            }
        }
        return this.CURRENCY_OPTIONS;
    }

    async getCurrency() {
        if (this.checkIfUserAgentPrerender()) return 'USD';
        return window.localStorage[CURRENCY_KEY] || await this.getCurrencyByCountry();
    }

    async getCurrencyByCountry() {
        const country = await this.getCountry();
        if (country) {
            const options = await this.getCurrencyOptions();
            return options.find(x => x.value === countryToCurrency[country])?.value ?? 'USD';
        }
    }

    async getUserFirstName(email) {
        return await this.apiService.doGet(`GetUserFirstName/${email}`);
    }

    async saveCurrency(currency) {
        await this.currencyService.getStoredCurrencyRates(currency);
        this.eventAggregator.publish('currency-updated', { currency: currency });
        signalBindings('currency-changed');
        window.localStorage[CURRENCY_KEY] = currency;
    }

    getDesiredCurrency(currencyCode) {
        return this.CURRENCY_OPTIONS.find(currency => currency.value === currencyCode);
    }

    async savePreviousCurrency(currency) {
        window.localStorage[PREVIOUS_CURRENCY_KEY] = currency;
    }

    async getPreviousCurrency() {
        return window.localStorage[PREVIOUS_CURRENCY_KEY] || await this.getCurrencyByCountry();
    }

    savePurchased(purchased) {
        window.localStorage[PURCHASED] = purchased;
    }

    getPurchased() {
        return window.localStorage[PURCHASED];
    }

    destroyPurchased() {
        window.localStorage.removeItem(PURCHASED);
    }

    async getGeolocation() {
        const response = await this.apiService.doGet('IpAddress/GetIpData');
        if (!response) return;
        this.geolocation = response;
        return response;
    }

    saveBlogsCurrentPageView(page) {
        window.localStorage[CURRENT_BLOGS_PAGE_VIEW] = page;
    }

    getBlogsCurrentPageView() {
        const currentBlogsPageView = window.localStorage[CURRENT_BLOGS_PAGE_VIEW];
        if (currentBlogsPageView) {
            return JSON.parse(currentBlogsPageView);
        }
    }

    async getCart(localCart = false, refresh = false) {
        if (this.currentUser && !localCart) {
            if (this.userCart && !refresh) {
                this.userCart.forEach((e, index) => {
                    this.userCart[index].tempQuantity = e.quantity;
                    this.userCart[index].quantity = this.thousandSeparatorValueConverter.toView(e.quantity);
                    this.userCart[index].selectedQuantity = e.quantity;
                });
                return this.userCart;
            }
            this.userCart = await this.customerService.getUserCartProducts();
            this.userCart?.forEach((e, index) => {
                this.userCart[index].cartItemId = index + 1;
                this.userCart[index].tempQuantity = e.quantity;
                this.userCart[index].quantity = this.thousandSeparatorValueConverter.toView(e.quantity);
                this.userCart[index].selectedQuantity = e.quantity;
            });
            return this.userCart;
        } else {
            const stringCart = window.localStorage[CART_KEY];
            if (stringCart && stringCart !== '[]' && stringCart !== '') {
                const cartItems = JSON.parse(stringCart);
                cartItems.forEach((e, index) => {
                    cartItems[index].tempQuantity = e.quantity;
                });
                return cartItems;
            } else {
                this.destroyCart();
            }
        }
    }

    async checkIfProductInCart(productId) {
        const cart = await this.getCart();
        if (cart) {
            for (const cartProduct of cart) {
                if (cartProduct.id === parseInt(productId)) {
                    return true;
                }
            }
        }
    }

    async saveCart(cart, skipEvent?, index?, updateWithoutRefresh?, cartId?, cartItem?, loggingIn?, updateCart?) {
        if (!skipEvent) {
            this.eventAggregator.publish('cart-updated', { cart: cart, index: index, updateWithoutRefresh: updateWithoutRefresh });
        }
        if (this.currentUser && (!skipEvent || (skipEvent && cartItem))) {
            if (cartId) {
                await this.customerService.destroyUserCartProducts(cartId);
            } else {
                const response = await this.customerService.storeUserCartProducts(cartItem ?? cart, this.currentUser?.id, loggingIn, updateCart);
                cart.forEach((e) => {
                    e.quantity = this.thousandSeparatorValueConverter.toView(e.quantity);
                    e.selectedQuantity = e.quantity;
                });
                if (response) {
                    const checkItem = Array.isArray(cartItem) ? cartItem[0] : cartItem;
                    const productCartIndex = cart.findIndex(x => x.cartItemId === checkItem.cartItemId || (x.id === checkItem.id && x.character === checkItem));

                    if (productCartIndex !== -1) {
                        cart[productCartIndex].userCartId = response;
                    }
                }
            }
        }
        window.localStorage[CART_KEY] = JSON.stringify(cart);
    }

    async destroyCart() {
        if (this.currentUser) {
            await this.customerService.destroyUserCartProducts();
        }
        window.localStorage.removeItem(CART_KEY);
        this.userCart = null;
        this.eventAggregator.publish('cart-updated', { cart: [] });
    }

    destroyReferralLink() {
        window.localStorage.removeItem(REFERRAL_LINK_KEY);
    }

    destroyReferrerLink() {
        window.localStorage.removeItem(REFERRER_LINK_KEY);
    }

    async saveReferralLink(linkName) {
        await this.referralService.submitReferralLink(linkName, '1');
        window.localStorage[REFERRAL_LINK_KEY] = linkName;
    }

    async saveReferrerLink(linkName) {
        await this.referralService.submitReferralLink(linkName, '2');
        window.localStorage[REFERRER_LINK_KEY] = linkName;
    }

    getReferralLink() {
        return window.localStorage[REFERRAL_LINK_KEY];
    }

    getReferrerLink() {
        return window.localStorage[REFERRER_LINK_KEY];
    }

    setPlatformLinkCookie(platformLink, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = '; expires=' + date.toUTCString();
        document.cookie = platformLink + '=' + value + expires + ';path=/';
    }

    async storeCartItem(item) {
        if (item.salePrice) {
            item.price = item.salePrice;
        }
        const cart = await this.getCart();
        item.cartItemId = cart?.length ? cart.length + 1 : 1;
        if (this.currentUser) {
            item.character = item.character ? item.character : null;
            if (item.productId) {
                item.id = item.productId;
            }
        }
        await this.helper.handleGtagEvent('add_to_cart', item, 'USD', item.price);
        await this.helper.handleFacebookPixelEvent('AddToCart', item, 'USD', item.price);
        if (cart) {
            for (const cartItem of cart) {
                if ((cartItem.productId === item.productId || cartItem.id === item.id) && !item.isService && cartItem.character === item.character) {
                    cartItem.selectedQuantity = parseFloat(item.quantity) + parseFloat(cartItem.quantity);
                    cartItem.quantity = parseFloat(item.quantity) + parseFloat(cartItem.quantity);
                    cartItem.price = item.price;
                    return this.saveCart(cart, true, null, null, null, [cartItem]);
                }
            }
            cart.push(item);
            this.saveCart(cart, null, null, null, null, [item]);
        } else {
            this.saveCart([item]);
        }
    }

    async updateCartItem(item, skipEvent) {
        const cart = await this.getCart();
        if (cart) {
            for (const cartItem of cart) {
                if (cartItem.id === item.id && cartItem.name === item.name) {
                    cartItem.character = item.character;
                    cartItem.quantity = this.thousandSeparatorValueConverter.toView(item.quantity.toString());
                    cartItem.selectedQuantity = parseFloat(item.quantity);
                    cartItem.insuranceId = item.insuranceId;
                    cartItem.purchasedInsurance = item.purchasedInsurance;
                    cartItem.deliveryMethod = item.deliveryMethod;
                    cartItem.deliveryMethodId = item.deliveryMethod?.id;
                    return this.saveCart(cart, skipEvent, null, null, null, cartItem, null, true);
                }
            }
        }
    }

    async deleteCartItem(product) {
        const cart = await this.getCart();
        let index;
        let cartId;
        if (product.cartItemId && cart.every(x => x.cartItemId)) {
            index = cart.findIndex(i => i.cartItemId === product.cartItemId);
        } else if (product.id) {
            index = cart.findIndex(i => i.id === product.id);
        } else {
            index = cart.findIndex(i => i.name === product.name);
        }
        await this.helper.handleGtagEvent('remove_from_cart', product, 'USD', product.price);
        if (index !== -1) {
            if (this.currentUser) {
                cartId = cart[index]?.userCartId;
            }
            cart.splice(index, 1);
        }
        await this.saveCart(cart, false, index, this.currentUser ? false : true, cartId);
        if (cart.length < 1) {
            return true;
        }
    }

    async validateCharacterName(product) {
        const cart = await this.getCart();
        return !cart.find(x => product.isService && x.name === product.name && x.character === product.character && (x.cartItemId !== product.cartItemId || x.userCartId !== product.userCartId));
    }

    async checkIfUserByPaymentMethodInBlackList(threshold: PaymentMethodThreshold[]) {
        const result = {
            selectedPaymentMethod: false,
            paymentMethods: false,
            filterPaymentMethods: false
        };
        if (!this.currentUser) {
            this.currentUser = await this.getProfile();
        }
        if (this.currentUser) {
            this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser?.email);
            if (this.userBlacklist?.some(x => x.level === 3 || x.level === 4 && (x.category === 2 || x.category === 3 || (x.addByVeriffGroup && x.veriffGroupActive)))) {
                result.paymentMethods = true;
                result.selectedPaymentMethod = true;
                return result;
            }
            if (this.userBlacklist?.some(x => x.level === 2 && (x.category === 1 || x.category === 2 || (x.addByVeriffGroup && x.veriffGroupActive)))) {
                result.filterPaymentMethods = true;
                if (
                    threshold.find(t => t.verificationTypeList.includes(VerificationType.ID) && t.operationTypeList.includes(ThresholdOperationType.Sell)) ||
                    threshold.find(t => t.verificationTypeList.includes(VerificationType.Phone) && t.operationTypeList.includes(ThresholdOperationType.Sell))
                ) {
                    result.selectedPaymentMethod = true;
                }
            }
        }
        return result;
    }

    checkIfUserAgentPrerender = () => navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;
}
