import './google-pay-checkout-form.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { debug, checkoutPublicKey, vgsGooglePayInboundRouteId, vgsVaultId, vgsOrganizationId, vgsEnv, googlePayMerchantId } from 'environment';
import { CurrencyService } from 'services/currency-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { RateLimiter } from 'limiter';
import { cardTypesFor3DSTrigger, automaticPaymentMethodsWithout3DS } from 'resources/constants';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/extensions/helper';
import { VGSDebitCreditCardService } from 'services/vgs-service';
import { ToastService } from 'services/toast-service';

@autoinject()
export class GooglePayCheckoutForm {
    parent;
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    constructor(
        private currencyService: CurrencyService,
        private eventAggregator: EventAggregator,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private camelCaseValueConverter: CamelCaseValueConverter,
        private helper: Helper,
        private vgsDebitCreditCardService: VGSDebitCreditCardService,
        private toastService: ToastService
    ) {
        this.rateLimiter = new RateLimiter({ tokensPerInterval: 2, interval: 3600000, fireImmediately: true });
    }

    @bindable totalPrice;
    @bindable preferredCurrency;
    @bindable selectedPaymentMethod;
    @bindable billing;
    @bindable challengeIframe;
    googlePayClient;
    googlePayViewModel;
    paymentToken;
    user;
    sizeChanged;
    width;
    deviceSessionId;
    dialogModule;
    longPollTimeout;
    rateLimiter;
    remainingRequests;

    tokenizationSpecification = {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            'gateway': 'verygoodsecurity',
            'gatewayMerchantId': vgsOrganizationId()
        }
    };

    cardPaymentMethod = {
        type: 'CARD',
        tokenizationSpecification: this.tokenizationSpecification,
        parameters: {
            allowedCardNetworks: ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'JCB'],
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS']
        }
    };

    googlePayConfiguration: any = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [this.cardPaymentMethod]
    };

    googleInbountRoute = `https://${vgsVaultId()}-${vgsGooglePayInboundRouteId()}.${vgsEnv()}.verygoodproxy.com/post`;
    cardTypesFor3DSTrigger = cardTypesFor3DSTrigger();
    automaticPaymentMethodsWithout3DS = automaticPaymentMethodsWithout3DS();

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.onGooglePayLoaded();
        this.handleEventSubscriptions();
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            if (this.selectedPaymentMethod?.paymentMethod.reference.includes('google-pay-direct') && this.user) {
                const element = document.querySelector('.ds-google-pay-box');
                if (element.children.length <= 0) {
                    this.googlePayViewModel.createAndAddButton();
                }
            }
        });
    }

    async onGooglePayLoaded() {
        this.googlePayClient = new google.payments.api.PaymentsClient({
            environment: debug() ? 'TEST' : 'PRODUCTION',
            paymentDataCallbacks: {
                onPaymentAuthorized: this.onPaymentAuthorized
            }
        });
        const isReadyToPay = await this.googlePayClient.isReadyToPay(this.googlePayConfiguration);
        if (isReadyToPay.result) {
            this.createAndAddButton();
        } else {
            //Prompt error information
            console.log('There was an error, GooglePay is no ready');
        }
    }

    async createAndAddButton() {
        const googlePayButton = this.googlePayClient.createButton({
            buttonType: 'pay',
            buttonSizeMode: 'fill',
            onClick: this.onGooglePayButtonClick,
            allowedPaymentMethods: [this.cardPaymentMethod]
        });
        const element = document.querySelector('.ds-google-pay-box');
        if (!element) return;
        element.innerHTML = '';
        element.appendChild(googlePayButton);
        const button = element.querySelector('button');
        if (this.width >= 576) {
            button.innerHTML = 'Pay now';
        } else {
            button.innerHTML = `Pay now • ${await this.currencyFormatValueConverter.toView(this.totalPrice)}`;
        }
    }

    onGooglePayButtonClick = async() => {
        if (this.parent.summaryButtonState !== 'disabled') {
            this.remainingRequests = await this.rateLimiter.removeTokens(1);

            if (this.remainingRequests < 0 && !debug()) {
                return this.toastService.showToast('Error', 'You’ve made a payment attempt too many times recently. Try again later.', 'error');
            }

            if (!this.parent.validBilling()) {
                this.toastService.showToast('Error', 'Please enter a valid billing address to proceed with your payment.', 'error');
                this.parent.paymentProcessing = false;
                this.parent.summaryButtonState = 'valid';
                return;
            }

            const paymentDataRequest = { ...this.googlePayConfiguration };
            const rate = await this.currencyService.getStoredCurrencyRates(this.preferredCurrency);
            paymentDataRequest.merchantInfo = {
                merchantId: googlePayMerchantId(),
                merchantName: 'Divica Sales'
            };
            paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION'];
            paymentDataRequest.transactionInfo = {
                totalPriceStatus: 'FINAL',
                totalPrice: ((this.totalPrice * rate).toFixed(2)).toString(),
                currencyCode: this.preferredCurrency,
                countryCode: this.billing.country ?? 'US'
            };
            paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod];

            if (this.parent.checkMinAndMaxValues) this.parent.checkMinAndMaxValues();
            const cartProductsValid = await this.parent.cartProductsValidation();
            if (!cartProductsValid) {
                return;
            }

            this.googlePayClient.loadPaymentData(paymentDataRequest)
                .then(paymentData => this.processPaymentData(paymentData))
                .catch((error) => {
                    if (error.statusCode !== 'CANCELED') {
                        console.error('Error: ', error);
                    }
                });
        }
    };

    async processPaymentData(paymentData) {
        this.parent.summaryButtonState = 'processing';

        try {
            this.deviceSessionId = await this.handleCheckoutFraudDetection();
        } catch (e) {
            console.log(e);
        }

        if (this.parent.vgsData) {
            this.parent.handleGooglePayDirectOrder(null, this.deviceSessionId);
            return;
        }

        const payload = {
            // eslint-disable-next-line camelcase
            google_pay_payload: {
                token: paymentData.paymentMethodData.tokenizationData.token
            }
        };

        try {
            const response = await fetch(this.googleInbountRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const json = await response.json();
                const finalizedPaymentData = this.camelCaseValueConverter.toView(JSON.parse(json.data));
                this.parent.vgsData = {
                    cardNumber: finalizedPaymentData.googlePayPayload.token.paymentMethodDetails.pan,
                    cardExp: `${finalizedPaymentData.googlePayPayload.token.paymentMethodDetails.expirationMonth} / ${finalizedPaymentData.googlePayPayload.token.paymentMethodDetails.expirationYear}`,
                    cardType: paymentData.paymentMethodData.info.cardNetwork,
                    lastFour: paymentData.paymentMethodData.info.cardDetails
                };

                this.parent.forceVerification = true;

                if (this.helper.excludeAll('google-pay-direct', this.automaticPaymentMethodsWithout3DS)) {
                    let threeDSResponse;
                    if (this.helper.includesSome(paymentData.paymentMethodData.info.cardNetwork.toLowerCase(), this.cardTypesFor3DSTrigger)) {
                        const threeDSRequest = {
                            amount: parseFloat(this.parent.totalPrice.toFixed(2)),
                            pan: finalizedPaymentData.googlePayPayload.token.paymentMethodDetails.pan,
                            year: finalizedPaymentData.googlePayPayload.token.paymentMethodDetails.expirationYear.toString().substring(2),
                            month: finalizedPaymentData.googlePayPayload.token.paymentMethodDetails.expirationMonth,
                            lastFour: paymentData.paymentMethodData.info.cardDetails,
                            cardType: paymentData.paymentMethodData.info.cardNetwork.toLowerCase(),
                            browser: this.helper.addBrowserInfoData()
                        };

                        threeDSResponse = await this.vgsDebitCreditCardService.handle3dsResult(threeDSRequest);
                    }

                    let threeDsResult;

                    if (threeDSResponse?.methodURL && threeDSResponse?.threeDSMethodData) {
                        await this.vgsDebitCreditCardService.handle3dsFingerprint(threeDSResponse.methodURL, threeDSResponse.threeDSMethodData);
                    }

                    if (threeDSResponse?.transactionId) {
                        threeDsResult = await this.vgsDebitCreditCardService.get3dsResult(threeDSResponse.transactionId);
                    }

                    if (threeDsResult?.status === 'C') {
                        this.challengeIframe = await this.vgsDebitCreditCardService.handle3dsChallenge(threeDsResult.acsURL, threeDsResult.creq);
                        await this.threeDsResultLongPoll(threeDSResponse.transactionId, threeDsResult.updateDate);
                        return;
                    }
                    this.parent.threeDsData = threeDsResult;
                }

                this.parent.handleGooglePayDirectOrder(null, this.deviceSessionId);
            } else {
                const innerResponse = await response.text();
                this.toastService.showToast('Error', innerResponse, 'error');
                this.parent.summaryButtonState = 'active';
            }
        } catch (error) {
            console.log(error);
            this.parent.summaryButtonState = 'active';
        }
    }

    onPaymentAuthorized() {
        return new Promise<any>((resolve) => {
            resolve({ transactionState: 'SUCCESS' });
        });
    }

    async handleCheckoutFraudDetection() {
        const risk = window.Risk.init(checkoutPublicKey());
        return await risk.publishRiskData();
    }

    challengeIframeChanged() {
        this.dialogModule?.dialog?.open();
    }

    async threeDsResultLongPoll(transactionId, initialDate, fromPayButton = false) {
        if (fromPayButton) {
            this.dialogModule?.dialog?.open();
            this.parent.summaryButtonState = 'processing';
        }

        const result = await this.vgsDebitCreditCardService.get3dsResult(transactionId);

        if (result.updateDate !== initialDate && result.status !== 'C') {
            this.dialogModule?.dialog?.close();
            this.parent.threeDsData = result;
            this.parent.handleGooglePayDirectOrder(null, this.deviceSessionId);
        } else {
            if (!this.dialogModule?.dialog.foundation.dialogOpen && !fromPayButton) {
                this.threeDsResultLongPoll(transactionId, initialDate, true);
                return;
            }

            this.longPollTimeout = setTimeout(() => {
                this.threeDsResultLongPoll(transactionId, initialDate);
            }, 4000);
        }
    }
}
