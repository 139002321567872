import './vgs-form.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { VGSDebitCreditCardService } from 'services/vgs-service';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { Helper } from 'resources/extensions/helper';
import { loadVGSCollect } from '@vgs/collect-js';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { vgsInboundRouteId, vgsVaultId, vgsEnv, checkoutPublicKey } from 'environment';
import { ToastService } from 'services/toast-service';
import { PAGE_NAMES, cardTypesFor3DSTrigger, automaticPaymentMethodsWithout3DS } from 'resources/constants';
import { SubscriptionService } from 'services/subscription-service';
import { SardineAi } from 'resources/helpers/sardine-ai';
import { RateLimiter } from 'limiter';
import { debug } from 'environment';

@autoinject()
export class VgsForm {
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private vgsDebitCreditCardService: VGSDebitCreditCardService,
        private validationController: ValidationController,
        private helper: Helper,
        private camelCaseValueConverter: CamelCaseValueConverter,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private toastService: ToastService,
        private subscriptionService: SubscriptionService,
        private sardineAi: SardineAi
    ) {
        this.validator = validationController;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.PAGE_NAMES_ROUTE = PAGE_NAMES.mapper(x => x.toRoute());
        this.rateLimiter = new RateLimiter({ tokensPerInterval: 2, interval: 3600000, fireImmediately: true });
    }

    bind(bindingContext) {
        this.parent = bindingContext;
        this.parent.summaryButtonState = 'disabled';
        this.parent.summaryButtonText = 'Pay Now';
        this.parent.summaryButtonFunction = this.handleFormSubmit.bind(this);
    }

    @bindable selectedPaymentMethod;
    @bindable billing;
    @bindable firstNameOnCard;
    @bindable lastNameOnCard;
    @bindable usingPreviousCard = false;
    @bindable userSavedCards;
    @bindable selectedCard;
    @bindable challengeIframe;
    initializeFormAttempts = 1;
    cardTypesFor3DSTrigger = cardTypesFor3DSTrigger();
    automaticPaymentMethodsWithout3DS = automaticPaymentMethodsWithout3DS();
    state;
    collect;
    vgsForm;
    readyingForm;
    updatingCardExpiry = false;
    currentYear;
    currentMonth;
    showGreenCheckMarkFirstNameExpiry;
    showErrorCheckMarkFirstNameExpiry;
    showGreenCheckMarkLastNameExpiry;
    showErrorCheckMarkLastNameExpiry;
    showGreenCheckMarkDateExpiry;
    showErrorCheckMarkDateExpiry;
    genericCardIcon = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF83XzE0Njg3KSI+DQo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDFfN18xNDY4NykiPg0KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAyXzdfMTQ2ODcpIj4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwM183XzE0Njg3KSI+DQo8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjI1IiBoZWlnaHQ9IjE3IiByeD0iMS41IiBzdHJva2U9IiMzOUUyOUQiLz4NCjxyZWN0IHg9IjEuMDM4MDkiIHk9IjUuMjUxMjYiIHdpZHRoPSIyMy45MjM5IiBoZWlnaHQ9IjEuNjkwMzkiIGZpbGw9IiMzOUUyOUQiIHN0cm9rZT0iIzM5RTI5RCIvPg0KPHJlY3QgeD0iMy42MzIwNyIgeT0iMTQuMDk4OSIgd2lkdGg9IjQuMDM1NTkiIGhlaWdodD0iMC44MDcxMTkiIGZpbGw9IiM1MEQ5QTQiIHN0cm9rZT0iIzM5RTI5RCIgc3Ryb2tlLXdpZHRoPSIwLjgwNzExOSIvPg0KPHJlY3QgeD0iMjAuNDg0NiIgeT0iMTQuMDk4OSIgd2lkdGg9IjEuODgzMjgiIGhlaWdodD0iMC44MDcxMTkiIGZpbGw9IiM1MEQ5QTQiIHN0cm9rZT0iIzM5RTI5RCIgc3Ryb2tlLXdpZHRoPSIwLjgwNzExOSIvPg0KPHJlY3QgeD0iMTYuODI2MiIgeT0iMTQuMDk4OSIgd2lkdGg9IjEuODgzMjgiIGhlaWdodD0iMC44MDcxMTkiIGZpbGw9IiM1MEQ5QTQiIHN0cm9rZT0iIzM5RTI5RCIgc3Ryb2tlLXdpZHRoPSIwLjgwNzExOSIvPg0KPC9nPg0KPC9nPg0KPC9nPg0KPC9nPg0KPGRlZnM+DQo8Y2xpcFBhdGggaWQ9ImNsaXAwXzdfMTQ2ODciPg0KPHJlY3Qgd2lkdGg9IjI2IiBoZWlnaHQ9IjE4IiBmaWxsPSJ3aGl0ZSIvPg0KPC9jbGlwUGF0aD4NCjxjbGlwUGF0aCBpZD0iY2xpcDFfN18xNDY4NyI+DQo8cmVjdCB3aWR0aD0iMjYiIGhlaWdodD0iMTgiIGZpbGw9IndoaXRlIi8+DQo8L2NsaXBQYXRoPg0KPGNsaXBQYXRoIGlkPSJjbGlwMl83XzE0Njg3Ij4NCjxyZWN0IHdpZHRoPSIyNiIgaGVpZ2h0PSIxOCIgZmlsbD0id2hpdGUiLz4NCjwvY2xpcFBhdGg+DQo8Y2xpcFBhdGggaWQ9ImNsaXAzXzdfMTQ2ODciPg0KPHJlY3Qgd2lkdGg9IjI2IiBoZWlnaHQ9IjE4IiBmaWxsPSJ3aGl0ZSIvPg0KPC9jbGlwUGF0aD4NCjwvZGVmcz4NCjwvc3ZnPg0K';
    css = {
        'box-sizing': 'border-box',
        'padding': '0 16px',
        'color': 'black !important',
        'font-size': '16px',
        'background-color': 'rgba(243, 245, 247, 1)',
        'border-radius': '4px',
        '&:hover': {
            'background-color': 'rgba(243, 245, 247, 1)'
        },
        '&::placeholder': {
            'color': 'rgba(0, 0, 0, 0.4)'
        },
        '&.valid': {
            'background-color': 'rgba(0, 180, 105, 0.05)'
        },
        '&.invalid.touched': {
            'background-color': 'rgba(253, 74, 74, 0.05)'
        },
        '+ .icon': {
            'right': '16px'
        }
    };

    parent;
    user;
    showGreenInputFirstNameOnCard;
    showGreenInputLastNameOnCard;
    PAGE_NAMES_ROUTE;
    validator;
    inboundResponse;
    dialogModule;
    longPollTimeout;
    cardExpiryDate;
    firstNameStopWatch;
    firstNameStopWatch2;
    lastNameStopWatch;
    lastNameStopWatch2;
    showErrorInputFirstNameOnCard;
    showErrorInputLastNameOnCard;
    timeouts;
    firstNameExpiry;
    lastNameExpiry;
    showBillingGreenCheckMark;
    emptySavedCards;
    showBillingErrorCheckMark;
    expiryDate;
    replaceValue;
    usedCardForCheckout;
    rateLimiter;
    remainingRequests;
    cvvDialogModule;
    cvvValid;
    cvvInvalid;
    cvv;
    showMiniSpinnerCvv;
    cvvStopWatch;
    cvvStopWatch2;
    miniSpinnerCvvStopwatch;
    cvvFocusInStopWatch;
    toastCvvSent;

    async attached() {
        const date = new Date;
        this.readyingForm = true;
        this.currentYear = parseInt(date.getFullYear().toString().substring(2));
        this.currentMonth = date.getMonth() + 1;
        [this.userSavedCards, this.user] = await Promise.all([
            this.vgsDebitCreditCardService.getUserCardData(),
            this.sessionService.getProfile()
        ]);
        this.usingPreviousCard = this.userSavedCards.length > 0;
        if (this.usingPreviousCard) this.readyingForm = false;
        this.selectedCard = this.userSavedCards.reduce((closest, item) => {
            const itemDate = new Date(item.lastUse);
            const closestDate = new Date(closest.lastUse);
            const itemDiff = Math.abs(Number(date) - Number(itemDate));
            const closestDiff = Math.abs(Number(date) - Number(closestDate));
            return itemDiff < closestDiff ? item : closest;
        }, this.userSavedCards[0]);
        this.parent.summaryButtonState = 'active';

        if (!this.usingPreviousCard) {
            await this.initVGSForm();
        }

        this.firstNameOnCard ? this.showGreenInputFirstNameOnCard = true : '';
        this.lastNameOnCard ? this.showGreenInputLastNameOnCard = true : '';
    }

    async initVGSForm() {
        try {
            if (this.vgsForm) {
                this.readyingForm = false;
                return;
            }

            this.readyingForm = true;
            this.collect = await loadVGSCollect({
                vaultId: vgsVaultId(),
                environment: vgsEnv(),
                version: '2.21.0'
            }).catch((e) => { console.log(e); });

            this.vgsForm = this.collect.init((state) => {
                this.state = state;
                this.helper.debounce(this, 'updatePayButton', 'updatePayButtonTimeout', 200, () => {
                    let formValid = true;
                    for (const [key] of Object.entries(state)) {
                        if (!state[key].isValid) {
                            formValid = false;
                            break;
                        }
                    }

                    this.parent.summaryButtonState = (this.usingPreviousCard && !this.selectedCard) || (!this.usingPreviousCard && !formValid) ? 'disabled' : 'active';
                    this.customerFullNameStyleChange();
                    this.readyingForm = false;
                });
            }).setRouteId(vgsInboundRouteId());

            this.vgsForm.field('#cc-number', {
                type: 'card-number',
                name: 'cardNumber',
                successColor: '#4F8A10',
                errorColor: '#D8000C',
                placeholder: 'Card Number',
                showCardIcon: true,
                validations: ['required', 'validCardNumber'],
                icons: {
                    cardPlaceholder: this.genericCardIcon
                },
                css: this.css
            });

            this.vgsForm.field('#cc-cvc', {
                type: 'card-security-code',
                name: 'cardCvc',
                successColor: '#4F8A10',
                errorColor: '#D8000C',
                placeholder: 'CVV',
                validations: ['required', 'validCardSecurityCode'],
                css: this.css
            });

            this.vgsForm.field('#cc-exp-date', {
                type: 'card-expiration-date',
                name: 'cardExp',
                successColor: '#4F8A10',
                errorColor: '#D8000C',
                placeholder: 'MM / YY',
                validations: ['required', 'validCardExpirationDate'],
                css: this.css
            });

            await this.sardineAi.handleSardineFlow(this.user.id, this.user.sardineSessionKey, 'vgs', this.vgsForm.fields);
        } catch (e) {
            if (this.initializeFormAttempts >= 5) {
                this.vgsForm = this.readyingForm = null;
                return;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.initializeFormAttempts++;
            this.vgsForm = null;
            await this.initVGSForm();
        }
    }

    async handleFormSubmit() {
        this.parent.summaryButtonState = 'processing';

        if (this.currentRoute === this.PAGE_NAMES_ROUTE.CART) {
            const cartProductsValid = await this.parent.cartProductsValidation();
            if (!cartProductsValid) {
                this.parent.summaryButtonState = 'active';
                return;
            }
        }

        if (!this.parent.checkBilling()) return;

        this.remainingRequests = await this.rateLimiter.removeTokens(1);

        if (this.remainingRequests < 0 && !debug()) {
            return this.toastService.showToast('Error', 'You’ve made a payment attempt too many times recently. Try again later.', 'error');
        }

        if (this.selectedPaymentMethod.paymentMethod.reference === 'checkout') {
            try {
                this.parent.checkoutDeviceSessionId = await this.handleCheckoutFraudDetection();
            } catch (e) {
                console.log(e);
            }
        }

        if (this.usingPreviousCard && this.selectedCard) {
            this.parent.vgsData = {
                id: this.selectedCard.id
            };

            if (this.selectedPaymentMethod.paymentMethod.reference === 'solidgate' && !this.selectedCard.solidgateRecurringToken) {
                this.cvvDialogModule?.dialog?.open();
                return;
            }

            await this.handleSavedCardFlow();
        } else {
            if (this.parent.vgsData) {
                this.parent.startOrder();
                return;
            }
            this.vgsForm.submit('/Webhook/VGSRouteInbound', {
                data: {
                    cardType: this.state.cardNumber.cardType,
                    amount: parseFloat(this.parent.totalPrice.toFixed(2)),
                    browser: this.helper.addBrowserInfoData()
                }
            }, async(status, response) => {
                if (status === 200) {
                    response = this.camelCaseValueConverter.toView(response);
                    this.inboundResponse = response;

                    if (!response || !response?.cardInfo) {
                        this.toastService.showToast('Error', 'An error occurred while processing your payment. Please try again.', 'error');
                        console.error(`VGS Error: ${response}`);
                        this.parent.summaryButtonState = 'active';
                        return;
                    }

                    let threeDsResult;
                    if (this.helper.excludeAll(this.selectedPaymentMethod.paymentMethod.reference, this.automaticPaymentMethodsWithout3DS)) {
                        if (response.auth3DSResponse?.methodURL && response.auth3DSResponse?.threeDSMethodData) {
                            await this.vgsDebitCreditCardService.handle3dsFingerprint(response.auth3DSResponse.methodURL, response.auth3DSResponse.threeDSMethodData);
                        }

                        if (response.auth3DSResponse?.transactionId) {
                            threeDsResult = await this.vgsDebitCreditCardService.get3dsResult(response.auth3DSResponse.transactionId);
                        }

                        if (threeDsResult?.status === 'C') {
                            this.challengeIframe = await this.vgsDebitCreditCardService.handle3dsChallenge(threeDsResult.acsURL, threeDsResult.creq);
                            await this.threeDsResultLongPoll(response.auth3DSResponse.transactionId, threeDsResult.updateDate);
                            return;
                        }
                    }

                    this.startOrder(threeDsResult);
                } else {
                    this.toastService.showToast('Error', response?.message, 'error');
                    this.parent.summaryButtonState = 'active';
                }
            }, (error) => {
                this.parent.summaryButtonState = 'active';
                console.log(error);
            });
        }
    }

    challengeIframeChanged() {
        this.dialogModule?.dialog?.open();
    }

    startOrder(final3dsResult) {
        if (this.inboundResponse) {
            this.inboundResponse.cardInfo.cardLastFour = this.state?.cardNumber.last4;
            this.inboundResponse.cardInfo.cardType = this.state?.cardNumber.cardType;
            this.parent.vgsData = this.inboundResponse.cardInfo;
        }
        this.parent.threeDsData = final3dsResult;
        this.parent.forceVerification = true;
        this.parent.startOrder();
    }

    async threeDsResultLongPoll(transactionId, initialDate, fromPayButton = false) {
        if (fromPayButton) {
            this.dialogModule?.dialog?.open();
            this.parent.summaryButtonState = 'processing';
        }

        const result = await this.vgsDebitCreditCardService.get3dsResult(transactionId);

        if (result.updateDate !== initialDate && result.status !== 'C') {
            this.dialogModule?.dialog?.close();
            this.startOrder(result);
        } else {
            if (!this.dialogModule?.dialog.foundation.dialogOpen && !fromPayButton) {
                this.threeDsResultLongPoll(transactionId, initialDate, true);
                return;
            }

            this.longPollTimeout = setTimeout(() => {
                this.threeDsResultLongPoll(transactionId, initialDate);
            }, 4000);
        }
    }

    getErrorText(id) {
        const field = this.state[id];

        if (field?.isDirty && !field?.isValid) {
            switch (id) {
                case 'card_cvc':
                    return 'Invalid CVV';
                case 'card_exp':
                    return 'Invalid EXP';
                case 'card_number':
                    return 'Invalid CCN';
                default:
                    return '';
            }
        }

        return '';
    }

    async firstNameOnCardOnKeyPress() {
        this.showGreenInputFirstNameOnCard = this.showErrorInputFirstNameOnCard = false;
        this.timeouts = [this.firstNameStopWatch, this.firstNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
            this.validator.validate();
        }
        this.firstNameStopWatch = setTimeout(async() => {
            if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                ValidationRules
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .on(this);
            } else {
                ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
            }
            const rules = await this.validator.validate();
            this.showGreenInputFirstNameOnCard = this.helper.validatorCheckOneCondition('firstNameOnCard', rules.results);
            if (!this.showGreenInputFirstNameOnCard) {
                await this.validator.reset();
                if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                    ValidationRules
                        .ensure('lastNameOnCard').required().withMessage('Last name required.')
                        .on(this);
                    this.validator.validate();
                }
                this.firstNameStopWatch2 = setTimeout(async() => {
                    if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                        ValidationRules
                            .ensure('firstNameOnCard').required().withMessage('First name required.')
                            .ensure('lastNameOnCard').required().withMessage('Last name required.')
                            .on(this);
                    } else {
                        ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
                    }
                    await this.validator.validate();
                    this.showErrorInputFirstNameOnCard = !this.helper.validatorCheckOneCondition('firstNameOnCard', rules.results);
                }, 2000);
            }
            if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                this.showGreenInputLastNameOnCard = this.helper.validatorCheckOneCondition('lastNameOnCard', rules.results);
                this.showErrorInputLastNameOnCard = !this.helper.validatorCheckOneCondition('lastNameOnCard', rules.results);
            }
        }, 2000);
    }

    firstNameOnFocusIn() {
        this.showGreenInputFirstNameOnCard = this.showErrorInputFirstNameOnCard = false;
        this.validator.reset();
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
            this.validator.validate();
        }
    }

    async checkFirstNameValidation() {
        this.timeouts = [this.firstNameStopWatch, this.firstNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
        } else {
            ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
        }
        const rules = await this.validator.validate();
        this.showGreenInputFirstNameOnCard = this.helper.validatorCheckOneCondition('firstNameOnCard', rules.results);
        this.showErrorInputFirstNameOnCard = !this.helper.validatorCheckOneCondition('firstNameOnCard', rules.results);
        this.customerFullNameStyleChange();
    }

    async lastNameOnCardOnKeyPress() {
        this.showGreenInputLastNameOnCard = this.showErrorInputLastNameOnCard = false;
        this.timeouts = [this.lastNameStopWatch, this.lastNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
            this.validator.validate();
        }
        this.lastNameStopWatch = setTimeout(async() => {
            if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                ValidationRules
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .on(this);
            } else {
                ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
            }
            const rules = await this.validator.validate();
            this.showGreenInputLastNameOnCard = this.helper.validatorCheckOneCondition('lastNameOnCard', rules.results);
            if (!this.showGreenInputLastNameOnCard) {
                await this.validator.reset();
                if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                    ValidationRules
                        .ensure('firstNameOnCard').required().withMessage('First name required.')
                        .on(this);
                    this.validator.validate();
                }
                this.lastNameStopWatch2 = setTimeout(async() => {
                    if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                        ValidationRules
                            .ensure('lastNameOnCard').required().withMessage('Last name required.')
                            .ensure('firstNameOnCard').required().withMessage('First name required.')
                            .on(this);
                    } else {
                        ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
                    }
                    const rules2 = await this.validator.validate();
                    this.showErrorInputLastNameOnCard = !this.helper.validatorCheckOneCondition('lastNameOnCard', rules2.results);
                }, 2000);
            }
            if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                this.showGreenInputFirstNameOnCard = this.helper.validatorCheckOneCondition('firstNameOnCard', rules.results);
                this.showErrorInputFirstNameOnCard = !this.helper.validatorCheckOneCondition('firstNameOnCard', rules.results);
            }
        }, 2000);
    }

    lastNameOnFocusIn() {
        this.showGreenInputLastNameOnCard = this.showErrorInputLastNameOnCard = false;
        this.validator.reset();
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
            this.validator.validate();
        }
    }

    async checkLastNameValidation() {
        this.timeouts = [this.lastNameStopWatch, this.lastNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
        } else {
            ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
        }
        const rules = await this.validator.validate();
        this.showGreenInputLastNameOnCard = this.helper.validatorCheckOneCondition('lastNameOnCard', rules.results);
        this.showErrorInputLastNameOnCard = !this.helper.validatorCheckOneCondition('lastNameOnCard', rules.results);
        this.customerFullNameStyleChange();
    }

    customerFullNameStyleChange() {
        if (!this.usingPreviousCard) {
            if (this.firstNameOnCard && this.lastNameOnCard && this.billing.city && this.billing.country && this.billing.street && this.billing.zip && this.showBillingGreenCheckMark) {
                this.parent.summaryButtonState = 'active';
            } else {
                this.parent.summaryButtonState = 'disabled';
            }
        } else {
            this.parent.summaryButtonState = 'active';
        }
    }

    async useNewCard() {
        this.usingPreviousCard = false;
        this.validator.reset();
        this.showErrorInputFirstNameOnCard = this.showErrorInputLastNameOnCard = null;
        this.parent.vgsData = null;
        this.parent.threeDsData = null;
        this.parent.summaryButtonFunction = this.handleFormSubmit.bind(this);
        this.usedCardForCheckout = null;
        await this.initVGSForm();
    }

    usePreviousCard() {
        this.usingPreviousCard = true;
        this.parent.summaryButtonState = 'active';
        this.parent.summaryButtonFunction = this.handleFormSubmit.bind(this);
        this.emptySavedCards = false;
        this.parent.vgsData = null;
        this.parent.threeDsData = null;
        this.setBillingForCard();
        this.vgsForm.reset();
    }

    isCardExpired(card) {
        const year = parseInt(card.expYear.substring(2));
        if (year < this.currentYear || (parseInt(card.expMonth) < this.currentMonth && this.currentYear === year)) {
            return true;
        }
        return false;
    }

    isCardExpiringSoon(card) {
        const year = parseInt(card.expYear.substring(2));
        if ((parseInt(card.expMonth) - this.currentMonth) <= 2 && parseInt(card.expMonth) >= this.currentMonth && this.currentYear === year) {
            return true;
        }
        return false;
    }

    getCardAddress(card) {
        if (card.address) {
            return `${card.address}, ${card.city}, ${card.state !== null ? card.state + ',' : ''} ${card.zip}, ${card.country.toUpperCase()}`;
        }
    }

    setBillingForCard() {
        if (this.selectedCard?.address) {
            this.billing = {
                zip: this.selectedCard.zip,
                street: this.selectedCard.address,
                country: this.selectedCard.country,
                state: this.selectedCard.state,
                city: this.selectedCard.city
            };
        }
    }

    selectedCardChanged() {
        this.setBillingForCard();
        if (this.updatingCardExpiry) {
            this.firstNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[0] : this.firstNameOnCard;
            this.lastNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[1] : this.lastNameOnCard;
            this.showGreenCheckMarkFirstNameExpiry = true;
            this.showGreenCheckMarkLastNameExpiry = true;
        }
    }

    updateExpiryOnClick() {
        this.updatingCardExpiry = !this.updatingCardExpiry;
        if (this.updatingCardExpiry) {
            this.firstNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[0] : this.firstNameOnCard;
            this.lastNameExpiry = this.selectedCard.name ? this.selectedCard.name.split(' ')[1] : this.lastNameOnCard;
            this.showGreenCheckMarkFirstNameExpiry = true;
            this.showGreenCheckMarkLastNameExpiry = true;
        }
    }

    async removeCreditCard(card) {
        if (this.subscriptionService.hasSubscription(this.user, true) && this.userSavedCards?.length <= 1) {
            this.toastService.showToast('Info', 'You currently have an active subscription. To delete your card, please first cancel your subscription.', 'info');
            return;
        }
        if (window.confirm('Are you sure you would like to remove this card?')) {
            const response = await this.vgsDebitCreditCardService.deleteUserCard(card.id);
            if (response) {
                const cardIndex = this.userSavedCards.findIndex(x => x.id === card.id);
                if (cardIndex > -1) {
                    this.userSavedCards.splice(cardIndex, 1);
                }
                if (this.userSavedCards?.length === 0) {
                    this.parent.shouldShowBlueLine = false;
                    this.usingPreviousCard = false;
                    this.showBillingGreenCheckMark = false;
                    this.showBillingErrorCheckMark = false;
                    this.emptySavedCards = true;
                    this.billing.zip = this.parent.user?.zip;
                    this.billing.street = this.parent.user?.address;
                    this.billing.country = this.parent.user?.country?.toUpperCase();
                    this.billing.state = this.parent.user?.state;
                    this.billing.city = this.parent.user?.city;
                    this.usedCardForCheckout = null;
                    await this.initVGSForm();
                } else {
                    this.selectedCard = this.userSavedCards[0];
                }
                this.toastService.showToast('Success!', 'Card removed.', 'success');
            }
        }
    }

    async updateCardExpiry() {
        try {
            ValidationRules
                .ensure('firstNameExpiry').required().withMessage('First name required.')
                .ensure('lastNameExpiry').required().withMessage('Last name required.')
                .ensure('expiryDate').required().matches(/^(0[1-9]|1[0-2]) \/ ([0-9]{2})$/)
                .satisfies((dateExp) => {
                    if (dateExp?.length === 7 && ((parseInt(dateExp.slice(-2)) < this.currentYear) || (parseInt(dateExp.substring(0, 2)) < this.currentMonth && parseInt(dateExp.slice(-2)) === this.currentYear))) {
                        return false;
                    }
                    return true;
                }).withMessage('Invalid Date Expiry.')
                .on(this);
            const result = await this.validator.validate();
            if (result.valid) {
                this.readyingForm = true;
                const response = await this.vgsDebitCreditCardService.updateUserCard(
                    `${this.firstNameExpiry} ${this.lastNameExpiry}`,
                    this.selectedCard.id,
                    this.expiryDate,
                    this.billing
                );
                if (response) {
                    this.usingPreviousCard = true;
                    const cardIndex = this.userSavedCards.findIndex(c => response.id === c.id);
                    if (cardIndex >= 0 && cardIndex < this.userSavedCards.length) {
                        this.userSavedCards.splice(cardIndex, 1, { ...response });
                    }
                    this.selectedCard = this.userSavedCards[cardIndex];
                    this.updatingCardExpiry = false;
                    this.toastService.showToast('Success!', 'Credit card details updated.', 'success');
                }
            } else {
                this.showGreenCheckMarkFirstNameExpiry = result.results.filter(r => r.propertyName === 'firstNameExpiry').every(r => r.valid);
                this.showErrorCheckMarkFirstNameExpiry = !this.showGreenCheckMarkFirstNameExpiry;
                this.showGreenCheckMarkLastNameExpiry = result.results.filter(r => r.propertyName === 'lastNameExpiry').every(r => r.valid);
                this.showErrorCheckMarkLastNameExpiry = !this.showGreenCheckMarkLastNameExpiry;
                this.showGreenCheckMarkDateExpiry = result.results.filter(r => r.propertyName === 'expiryDate').every(r => r.valid);
                this.showErrorCheckMarkDateExpiry = !this.showGreenCheckMarkDateExpiry;
                this.toastService.showToast('Error', 'Some required information is missing or incorrect. Please correct the address fields and try again.', 'error');
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.readyingForm = false;
        }
    }

    async checkFirstNameExpiryOnKeyPress() {
        ValidationRules.ensure('firstNameExpiry').required().on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkFirstNameExpiry = rules.valid;
        this.showErrorCheckMarkFirstNameExpiry = !rules.valid;
    }

    async checkLastNameExpiryOnKeyPress() {
        ValidationRules.ensure('lastNameExpiry').required().on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkLastNameExpiry = rules.valid;
        this.showErrorCheckMarkLastNameExpiry = !rules.valid;
    }

    async checkExpiryDateOnKeyPress(event) {
        ValidationRules
            .ensure('expiryDate').required().matches(/^(0[1-9]|1[0-2]) \/ ([0-9]{2})$/)
            .satisfies((dateExp) => {
                if (dateExp?.length === 7 && ((parseInt(dateExp.slice(-2)) < this.currentYear) || (parseInt(dateExp.substring(0, 2)) < this.currentMonth && parseInt(dateExp.slice(-2)) === this.currentYear))) {
                    return false;
                }
                return true;
            }).withMessage('Invalid Date Expiry.')
            .on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkDateExpiry = rules.valid;
        this.showErrorCheckMarkDateExpiry = !rules.valid;
        if (this.cardExpiryDate.value.length === 2 && !(event.keyCode === 46 || event.keyCode === 8)) {
            this.cardExpiryDate.value += ' / ';
        } else if (this.cardExpiryDate.value.length === 3) {
            this.replaceValue = this.cardExpiryDate.value.slice(-1);
            this.cardExpiryDate.value = this.cardExpiryDate.value.substring(0, 2);
            this.cardExpiryDate.value += ' / ';
            this.cardExpiryDate.value += this.replaceValue;
        } else if ((this.cardExpiryDate.value.length === 5 || this.cardExpiryDate.value.length === 4) && (event.keyCode === 46 || event.keyCode === 8)) {
            this.cardExpiryDate.value = this.cardExpiryDate.value.slice(0, 2);
        }
    }

    async handleCheckoutFraudDetection() {
        const risk = window.Risk.init(checkoutPublicKey());
        return await risk.publishRiskData();
    }

    @computedFrom('router.currentInstruction.config.name')
    get currentRoute() {
        return this.router?.currentInstruction?.config?.name;
    }

    @computedFrom('currentRoute')
    get cardsColumns() {
        const defaultClasses = {
            [this.PAGE_NAMES_ROUTE.BALANCE]: 'col-lg-6 col-xxl-4 pe-0',
            [this.PAGE_NAMES_ROUTE.SUBSCRIPTION]: 'col-md-4'
        };
        return defaultClasses[this.currentRoute] ?? 'col-md-6 col-lg-4';
    }

    @computedFrom('currentRoute')
    get isSubscriptionRoute() {
        return this.currentRoute === this.PAGE_NAMES_ROUTE.SUBSCRIPTION;
    }

    @computedFrom('currentRoute')
    get isBalanceRoute() {
        return this.currentRoute === this.PAGE_NAMES_ROUTE.BALANCE;
    }

    async submitCvv() {
        if (this.cvvValid) {
            this.cvvDialogModule?.dialog?.close();
            this.parent.vgsData.cardCvc = this.cvv;
            await this.handleSavedCardFlow();
        }
    }

    cvvOnClose() {
        this.parent.parent.summaryButtonState = 'active';
        this.cvv = undefined;
        this.cvvValid = this.cvvInvalid = this.showMiniSpinnerCvv = false;
    }

    async cvvUpdatedOnKeyPress(event) {
        if (!event?.keyCode) {
            return;
        }
        this.cvvValid = this.cvvInvalid = this.showMiniSpinnerCvv = false;
        this.timeouts = [this.miniSpinnerCvvStopwatch, this.cvvStopWatch, this.cvvStopWatch2, this.cvvFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (!this.cvv) return;
        this.miniSpinnerCvvStopwatch = setTimeout(() => {
            this.showMiniSpinnerCvv = true;
        }, 1000);
        this.cvvStopWatch = setTimeout(async() => {
            ValidationRules
                .ensure('cvv').required().minLength(3).maxLength(4)
                .on(this);
            const rules = await this.validator.validate();
            const checkValidation = this.helper.validatorCheckOneCondition('cvv', rules.results);
            if (!checkValidation) {
                await this.validator.reset();
                this.cvvStopWatch2 = setTimeout(async() => {
                    ValidationRules
                        .ensure('cvv').required().minLength(3).maxLength(4)
                        .on(this);
                    const rules2 = await this.validator.validate();
                    this.showMiniSpinnerCvv = false;
                    this.cvvInvalid = !this.helper.validatorCheckOneCondition('cvv', rules2.results);
                    this.toastCvvSent = true;
                    this.toastService.showToast('Error', 'Please enter a valid CVV.', 'error');
                }, 2000);
            } else {
                this.cvvValid = true;
                this.cvvInvalid = this.showMiniSpinnerCvv = false;
            }
        }, 2000);
    }

    cvvUpdatedOnFocusIn() {
        this.cvvValid = this.cvvInvalid = this.showMiniSpinnerCvv = false;
        this.toastCvvSent = false;
        this.validator.reset();

        this.cvvFocusInStopWatch = setTimeout(() => {
            if (this.cvv !== undefined) {
                const event = {
                    keyCode: 'focusin'
                };
                this.cvvUpdatedOnKeyPress(event);
            }
        });
    }

    async checkCvvValidation() {
        this.timeouts = [this.miniSpinnerCvvStopwatch, this.cvvStopWatch, this.cvvStopWatch2, this.cvvFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.cvv !== undefined) {
            ValidationRules
                .ensure('cvv').required().minLength(3).maxLength(4)
                .on(this);
            const rules = await this.validator.validate();
            const checkValidation = this.helper.validatorCheckOneCondition('cvv', rules.results);

            this.showMiniSpinnerCvv = false;
            if (!checkValidation && !this.toastCvvSent) {
                this.cvvInvalid = true;
                this.toastService.showToast('Error', 'Please enter a valid CVV.', 'error');
            } else if (checkValidation) {
                this.cvvValid = true;
                this.cvvInvalid = false;
            }
        }
    }

    async handleSavedCardFlow() {
        if (this.usedCardForCheckout !== this.selectedCard && this.helper.excludeAll(this.selectedPaymentMethod.paymentMethod.reference, this.automaticPaymentMethodsWithout3DS)) {
            let threeDSResponse;
            if (this.helper.includesSome(this.selectedCard.cardType.toLowerCase(), this.cardTypesFor3DSTrigger)) {
                const threeDSRequest = {
                    amount: parseFloat(this.parent.totalPrice.toFixed(2)),
                    year: this.selectedCard.expYear.substring(2),
                    month: this.selectedCard.expMonth,
                    lastFour: this.selectedCard.lastFour,
                    cardType: this.selectedCard.cardType.toLowerCase(),
                    browser: this.helper.addBrowserInfoData()
                };

                threeDSResponse = await this.vgsDebitCreditCardService.handle3dsResult(threeDSRequest);
            }

            let threeDsResult;
            if (threeDSResponse?.methodURL && threeDSResponse?.threeDSMethodData) {
                await this.vgsDebitCreditCardService.handle3dsFingerprint(threeDSResponse.methodURL, threeDSResponse.threeDSMethodData);
            }

            if (threeDSResponse?.transactionId) {
                threeDsResult = await this.vgsDebitCreditCardService.get3dsResult(threeDSResponse.transactionId);
            }

            if (threeDsResult?.status === 'C') {
                this.challengeIframe = await this.vgsDebitCreditCardService.handle3dsChallenge(threeDsResult.acsURL, threeDsResult.creq);
                await this.threeDsResultLongPoll(threeDSResponse.transactionId, threeDsResult.updateDate);
                return;
            }
            this.parent.threeDsData = threeDsResult;
        }
        this.parent.forceVerification = true;
        this.usedCardForCheckout = this.selectedCard;
        this.parent.startOrder();
    }
}
