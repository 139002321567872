import './paysafe-checkout-form.scss';
import { Billing } from 'services/models/purchase-flow/billing';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { CurrencyService } from 'services/currency-service';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { paysafeApiKey, paysafeEnvironment, paysafeIgnoreCc, paysafeUsdAccount, paysafeCadAccount, websiteShortCode, debug } from 'environment';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { RateLimiter } from 'limiter';
import { Card } from 'services/models/purchase-flow/card';
import { PaymentMethodWebsite } from 'services/models/purchase-flow/paymentMethodWebsite';

@autoinject()
export class PaysafeCheckoutForm {
    constructor(
        private sessionService: SessionService,
        private customerService: CustomerService,
        private currencyService: CurrencyService,
        private validationController: ValidationController,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private toastService: ToastService) {
        this.validator = validationController;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.rateLimiter = new RateLimiter({ tokensPerInterval: 2, interval: 3600000, fireImmediately: true });
    }

    bind(bindingContext) {
        this.parent = bindingContext;
        this.parent.summaryButtonState = 'disabled';
        this.parent.summaryButtonText = 'Pay now';
        this.parent.summaryButtonFunction = this.authorizeCharge.bind(this);
    }

    validator;
    parent;
    paysafeVaultedProfile;
    customer;
    @bindable firstNameOnCard;
    @bindable lastNameOnCard;
    @bindable billing : Billing = {
        street: '',
        country: '',
        city: '',
        state: '',
        zip: ''
    };

    @bindable preferredCurrency;
    @bindable forceCad;
    @bindable totalPrice;
    @bindable paymentToken;
    @bindable loading;
    @bindable usingPreviousCard = false;
    @bindable selectedPaymentMethod: PaymentMethodWebsite;
    @bindable showBillingGreenCheckMark;
    @bindable showBillingErrorCheckMark;
    @bindable emptySavedCards = false;
    @bindable isBalance = false;
    @observable selectedCard: Card = {};

    availableCards = [];
    fieldsLoaded = false;
    paysafeInstance;
    cardSource = '/payment-methods/generic.svg';
    cardHeight = 20;
    readyingForm;
    currentYear;
    currentMonth;
    showGreenCheckMarkFirstNameExpiry;
    showErrorCheckMarkFirstNameExpiry;
    showGreenCheckMarkLastNameExpiry;
    showErrorCheckMarkLastNameExpiry;
    showGreenCheckMarkDateExpiry;
    showErrorCheckMarkDateExpiry;
    firstNameStopWatch;
    firstNameStopWatch2;
    lastNameStopWatch;
    lastNameStopWatch2;
    showGreenInputFirstNameOnCard;
    showGreenInputLastNameOnCard;
    cadRate;
    updatingCardExpiry;
    firstNameExpiry;
    lastNameExpiry;
    showCcnError;
    showCreditCardType;
    showExpError;
    showCvvError;
    showErrorInputFirstNameOnCard;
    showErrorInputLastNameOnCard;
    timeouts;
    cardNumberTextField;
    cvvTextField;
    expiryDateTextField;
    ignore3DS;
    cardExpiryDate;
    replaceValue;
    expiryDate;
    fieldsArray : HTMLElement[];
    ccnNotFirstTimeWriting: boolean;
    expiryDateNotFirstTimeWriting: boolean;
    cvvNotFirstTimeWriting: boolean;
    rateLimiter;
    remainingRequests;

    attached() {
        this.paysafeVaultedProfile?.cards?.length > 0 ? this.usingPreviousCard = true : this.usingPreviousCard = false;
        this.usingPreviousCard ? this.parent.shouldShowBlueLine = true : this.parent.shouldShowBlueLine = false;
        this.usingPreviousCard ? this.parent.summaryButtonState = 'active' : this.parent.summaryButtonState = 'disabled';
        this.fieldsLoaded && !this.usingPreviousCard ? this.initializeCreditCardFields() : '';
        this.emptySavedCards = false;
    }

    async created() {
        try {
            this.readyingForm = true;
            this.customer = await this.sessionService.getProfile();
            this.paysafeVaultedProfile = await this.customerService.getPaysafeVaultProfile();
            const date = new Date();
            this.currentYear = parseInt(date.getFullYear().toString().substring(2));
            this.currentMonth = date.getMonth() + 1;
            if (this.paysafeVaultedProfile?.cards?.length > 0) {
                this.usingPreviousCard = true;
                for (const paymentSource of this.paysafeVaultedProfile.cards) {
                    paymentSource.cardExpiry.month.toString().length < 2 ? paymentSource.cardExpiry.month = '0' + paymentSource.cardExpiry.month.toString() : paymentSource.cardExpiry.month;
                    paymentSource.cardExpiry.year = paymentSource.cardExpiry.year.toString().substring(2);
                    this.availableCards.push(paymentSource);
                }
                if (this.availableCards?.length > 0) {
                    const paysafeVaultLastUsedCard = await this.customerService.getPaysafeVaultLastUsedCard();
                    if (paysafeVaultLastUsedCard) {
                        this.selectedCard = this.availableCards.find(c => paysafeVaultLastUsedCard.lastUsedCard?.lastDigits === c.lastDigits &&
                            paysafeVaultLastUsedCard.lastUsedCard?.type === c.cardType);
                        if (!this.selectedCard) {
                            this.selectedCard = this.availableCards[0];
                        }
                    } else {
                        this.selectedCard = this.availableCards[0];
                    }
                }
                this.parent.summaryButtonState = 'active';
                this.parent.shouldShowBlueLine = true;
                this.setBillingForCard();
            } else {
                this.billing = {
                    street: '',
                    country: '',
                    city: '',
                    state: '',
                    zip: ''
                };
                this.billing.zip = this.customer?.zip;
                this.billing.street = this.customer?.address;
                this.billing.country = this.customer?.country?.toUpperCase();
                this.billing.state = this.customer?.state;
                this.billing.city = this.customer?.city;
            }

            this.firstNameOnCard = this.customer?.firstName;
            this.lastNameOnCard = this.customer?.lastName;
            this.firstNameOnCard ? this.showGreenInputFirstNameOnCard = true : '';
            this.lastNameOnCard ? this.showGreenInputLastNameOnCard = true : '';
            this.cadRate = await this.currencyService.getStoredCurrencyRates('CAD');

            if (!this.usingPreviousCard) {
                this.parent.shouldShowBlueLine = false;
                this.initializeCreditCardFields();
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.readyingForm = false;
            this.loading = false;
        }
    }

    setBillingForCard() {
        if (this.selectedCard?.billingAddressId) {
            const foundAddress = this.paysafeVaultedProfile?.addresses?.find(x => x.id === this.selectedCard.billingAddressId);
            if (foundAddress) {
                this.billing = {
                    zip: foundAddress.zip,
                    street: foundAddress.street,
                    country: foundAddress.country,
                    state: foundAddress.state,
                    city: foundAddress.city
                };
            } else {
                this.billing = {
                    street: '',
                    country: '',
                    city: '',
                    state: '',
                    zip: ''
                };
            }
        }
    }

    selectedCardChanged() {
        this.setBillingForCard();
        if (this.updatingCardExpiry) {
            this.firstNameExpiry = this.selectedCard.holderName && !this.selectedCard.holderName?.includes('undefined') ? this.selectedCard.holderName.split(' ')[0] : this.firstNameOnCard;
            this.lastNameExpiry = this.selectedCard.holderName && !this.selectedCard.holderName?.includes('undefined') ? this.selectedCard.holderName.split(' ')[1] : this.lastNameOnCard;
            this.showGreenCheckMarkFirstNameExpiry = true;
            this.showGreenCheckMarkLastNameExpiry = true;
        }
    }

    updateExpiryOnClick() {
        this.updatingCardExpiry = !this.updatingCardExpiry;
        if (this.updatingCardExpiry) {
            this.firstNameExpiry = this.selectedCard.holderName && !this.selectedCard.holderName?.includes('undefined') ? this.selectedCard.holderName.split(' ')[0] : this.firstNameOnCard;
            this.lastNameExpiry = this.selectedCard.holderName && !this.selectedCard.holderName?.includes('undefined') ? this.selectedCard.holderName.split(' ')[1] : this.lastNameOnCard;
            this.showGreenCheckMarkFirstNameExpiry = true;
            this.showGreenCheckMarkLastNameExpiry = true;
        }
    }

    initializeCreditCardFields() {
        this.readyingForm = true;
        if (this.fieldsLoaded) {
            const cardNumberEl = document.getElementById('cardNumber');
            cardNumberEl.removeChild(cardNumberEl.firstChild);
            cardNumberEl.classList.remove('ds-input--success');
            cardNumberEl.classList.remove('ds-input--error');
            const expiryDateEl = document.getElementById('expiryDate');
            expiryDateEl.removeChild(expiryDateEl.firstChild);
            expiryDateEl.classList.remove('ds-input--success');
            expiryDateEl.classList.remove('ds-input--error');
            const cvvEl = document.getElementById('cvv');
            cvvEl.removeChild(cvvEl.firstChild);
            cvvEl.classList.remove('ds-input--success');
            cvvEl.classList.remove('ds-input--error');
            this.showCcnError = this.showCreditCardType = this.showExpError = this.showCvvError = false;
            this.cardSource = '/payment-methods/generic.svg';
        }

        window.paysafe.fields.setup(paysafeApiKey(), {
            environment: paysafeEnvironment(),
            fields: {
                cardNumber: {
                    selector: '#cardNumber',
                    placeholder: 'Card Number'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'CVV',
                    optional: false
                },
                expiryDate: {
                    selector: '#expiryDate',
                    placeholder: 'MM / YY'
                }
            },
            style: {
                input: {
                    'font-family': 'Roboto ,Helvetica, Arial, sans-serif',
                    'font-weight': 'normal',
                    'font-size': '16px',
                    'color': 'rgba(59, 60, 81, 1)'
                },
                '.invalid': {
                    color: 'rgba(59, 60, 81, 1)'
                },
                '.valid': {
                    color: 'rgba(59, 60, 81, 1)'
                },
                '.invalid:focus': {
                    color: 'rgba(59, 60, 81, 1)'
                },
                '.valid:focus': {
                    color: 'rgba(59, 60, 81, 1)'
                },
                '::placeholder': {
                    color: 'rgba(59, 60, 81, 0.75) !important'
                }
            }
        }, (instance, error) => {
            if (!error) {
                this.fieldsLoaded = true;
            }
            this.paysafeInstance = instance;
            this.cardBrandRecognition();
            this.fieldsStyleChange();
        });
        this.parent.summaryButtonState = 'disabled';
        this.readyingForm = false;
    }

    useNewCard() {
        this.parent.shouldShowBlueLine = false;
        this.usingPreviousCard = false;
        this.validator.reset();
        this.showErrorInputFirstNameOnCard = this.showErrorInputLastNameOnCard = null;
        this.initializeCreditCardFields();
    }

    usePreviousCard() {
        this.parent.shouldShowBlueLine = true;
        this.usingPreviousCard = true;
        this.parent.summaryButtonState = 'active';
        this.emptySavedCards = false;
        this.setBillingForCard();
    }

    async handleUsingPreviousCardIfExisting() {
        try {
            this.availableCards = [];
            this.paysafeVaultedProfile = await this.customerService.getPaysafeVaultProfile();
            if (this.paysafeVaultedProfile?.cards?.length > 0) {
                this.usingPreviousCard = true;
                for (const paymentSource of this.paysafeVaultedProfile.cards) {
                    paymentSource.cardExpiry.month.toString().length < 2 ? paymentSource.cardExpiry.month = '0' + paymentSource.cardExpiry.month.toString() : paymentSource.cardExpiry.month;
                    paymentSource.cardExpiry.year = paymentSource.cardExpiry.year.toString().substring(2);
                    this.availableCards.push(paymentSource);
                }
                if (this.availableCards?.length > 0) {
                    const paysafeVaultLastUsedCard = await this.customerService.getPaysafeVaultLastUsedCard();
                    if (paysafeVaultLastUsedCard) {
                        this.selectedCard = this.availableCards.find(c => paysafeVaultLastUsedCard.lastUsedCard?.lastDigits === c.lastDigits &&
                                        paysafeVaultLastUsedCard.lastUsedCard?.type === c.cardType);
                        if (!this.selectedCard) {
                            this.selectedCard = this.availableCards[0];
                        }
                    } else {
                        this.selectedCard = this.availableCards[0];
                    }
                }
                this.setBillingForCard();
                this.parent.shouldShowBlueLine = true;
                this.parent.summaryButtonState = 'active';
            } else {
                this.billing.zip = this.customer?.zip;
                this.billing.street = this.customer?.address;
                this.billing.country = this.customer?.country?.toUpperCase();
                this.billing.state = this.customer?.state;
                this.billing.city = this.customer?.city;
            }
        } catch (e) {
            console.log(e);
        }
    }

    isCardExpiringSoon(card) {
        if ((parseInt(card.cardExpiry.month) - this.currentMonth) <= 2 && parseInt(card.cardExpiry.month) >= this.currentMonth && this.currentYear === parseInt(card.cardExpiry.year)) {
            return true;
        }
        return false;
    }

    isCardExpired(card) {
        if (parseInt(card.cardExpiry.year) < this.currentYear || (parseInt(card.cardExpiry.month) < this.currentMonth && this.currentYear === parseInt(card.cardExpiry.year))) {
            return true;
        }
        return false;
    }

    async firstNameOnCardOnKeyPress() {
        this.showGreenInputFirstNameOnCard = this.showErrorInputFirstNameOnCard = false;
        this.timeouts = [this.firstNameStopWatch, this.firstNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
            this.validator.validate();
        }
        this.firstNameStopWatch = setTimeout(async() => {
            if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                ValidationRules
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .on(this);
            } else {
                ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
            }
            const rules = await this.validator.validate();
            this.showGreenInputFirstNameOnCard = this.validatorCheckOneCondition('firstNameOnCard', rules.results);
            if (!this.showGreenInputFirstNameOnCard) {
                await this.validator.reset();
                if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                    ValidationRules
                        .ensure('lastNameOnCard').required().withMessage('Last name required.')
                        .on(this);
                    this.validator.validate();
                }
                this.firstNameStopWatch2 = setTimeout(async() => {
                    if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                        ValidationRules
                            .ensure('firstNameOnCard').required().withMessage('First name required.')
                            .ensure('lastNameOnCard').required().withMessage('Last name required.')
                            .on(this);
                    } else {
                        ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
                    }
                    await this.validator.validate();
                    this.showErrorInputFirstNameOnCard = !this.validatorCheckOneCondition('firstNameOnCard', rules.results);
                }, 2000);
            }
            if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
                this.showGreenInputLastNameOnCard = this.validatorCheckOneCondition('lastNameOnCard', rules.results);
                this.showErrorInputLastNameOnCard = !this.validatorCheckOneCondition('lastNameOnCard', rules.results);
            }
        }, 2000);
    }

    firstNameOnFocusIn() {
        this.showGreenInputFirstNameOnCard = this.showErrorInputFirstNameOnCard = false;
        this.validator.reset();
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
            this.validator.validate();
        }
    }

    async checkFirstNameValidation() {
        this.timeouts = [this.firstNameStopWatch, this.firstNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.lastNameOnCard !== undefined && this.showErrorInputLastNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .on(this);
        } else {
            ValidationRules.ensure('firstNameOnCard').required().withMessage('First name required.').on(this);
        }
        const rules = await this.validator.validate();
        this.showGreenInputFirstNameOnCard = this.validatorCheckOneCondition('firstNameOnCard', rules.results);
        this.showErrorInputFirstNameOnCard = !this.validatorCheckOneCondition('firstNameOnCard', rules.results);
        this.customerFullNameStyleChange();
    }

    async lastNameOnCardOnKeyPress() {
        this.showGreenInputLastNameOnCard = this.showErrorInputLastNameOnCard = false;
        this.timeouts = [this.lastNameStopWatch, this.lastNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
            this.validator.validate();
        }
        this.lastNameStopWatch = setTimeout(async() => {
            if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                ValidationRules
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .on(this);
            } else {
                ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
            }
            const rules = await this.validator.validate();
            this.showGreenInputLastNameOnCard = this.validatorCheckOneCondition('lastNameOnCard', rules.results);
            if (!this.showGreenInputLastNameOnCard) {
                await this.validator.reset();
                if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                    ValidationRules
                        .ensure('firstNameOnCard').required().withMessage('First name required.')
                        .on(this);
                    this.validator.validate();
                }
                this.lastNameStopWatch2 = setTimeout(async() => {
                    if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                        ValidationRules
                            .ensure('lastNameOnCard').required().withMessage('Last name required.')
                            .ensure('firstNameOnCard').required().withMessage('First name required.')
                            .on(this);
                    } else {
                        ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
                    }
                    const rules2 = await this.validator.validate();
                    this.showErrorInputLastNameOnCard = !this.validatorCheckOneCondition('lastNameOnCard', rules2.results);
                }, 2000);
            }
            if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
                this.showGreenInputFirstNameOnCard = this.validatorCheckOneCondition('firstNameOnCard', rules.results);
                this.showErrorInputFirstNameOnCard = !this.validatorCheckOneCondition('firstNameOnCard', rules.results);
            }
        }, 2000);
    }

    lastNameOnFocusIn() {
        this.showGreenInputLastNameOnCard = this.showErrorInputLastNameOnCard = false;
        this.validator.reset();
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
            this.validator.validate();
        }
    }

    async checkLastNameValidation() {
        this.timeouts = [this.lastNameStopWatch, this.lastNameStopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.firstNameOnCard !== undefined && this.showErrorInputFirstNameOnCard) {
            ValidationRules
                .ensure('lastNameOnCard').required().withMessage('Last name required.')
                .ensure('firstNameOnCard').required().withMessage('First name required.')
                .on(this);
        } else {
            ValidationRules.ensure('lastNameOnCard').required().withMessage('Last name required.').on(this);
        }
        const rules = await this.validator.validate();
        this.showGreenInputLastNameOnCard = this.validatorCheckOneCondition('lastNameOnCard', rules.results);
        this.showErrorInputLastNameOnCard = !this.validatorCheckOneCondition('lastNameOnCard', rules.results);
        this.customerFullNameStyleChange();
    }

    validatorCheckOneCondition(field, results): boolean {
        let valid = true;
        for (const result of results) {
            if (result.propertyName === field && !result.valid) {
                valid = false;
            }
        }
        return valid;
    }

    async authorizeCharge() {
        try {
            this.remainingRequests = await this.rateLimiter.removeTokens(1);

            if (this.remainingRequests < 0 && !debug()) {
                return this.toastService.showToast('Error', 'You’ve made a payment attempt too many times recently. Try again later.', 'error');
            }

            if (this.billing.country === 'CA' || this.billing.country === 'US') {
                if (!this.billing.country || !this.billing.state || !this.billing.city || !this.billing.street || !this.billing.zip) {
                    return this.toastService.showToast('Invalid billing address!', 'Some required information is missing or incorrect. Please correct the address fields and try again.', 'error');
                }
            } else {
                if (!this.billing.country || !this.billing.city || !this.billing.street || !this.billing.zip) {
                    return this.toastService.showToast('Invalid billing address!', 'Some required information is missing or incorrect. Please correct the address fields and try again.', 'error');
                }
            }

            if (!this.usingPreviousCard) {
                if (!this.fieldsLoaded) {
                    return;
                }
                ValidationRules
                    .ensure('firstNameOnCard').required().withMessage('First name required.')
                    .ensure('lastNameOnCard').required().withMessage('Last name required.')
                    .on(this);
                const rules = await this.validator.validate();
                if (!rules.valid) {
                    if (this.firstNameOnCard) {
                        this.showGreenInputFirstNameOnCard = true;
                        this.showErrorInputFirstNameOnCard = !this.showGreenInputFirstNameOnCard;
                    } else {
                        this.showErrorInputFirstNameOnCard = true;
                        this.showGreenInputFirstNameOnCard = !this.showErrorInputFirstNameOnCard;
                    }
                    if (this.lastNameOnCard) {
                        this.showGreenInputLastNameOnCard = true;
                        this.showErrorInputLastNameOnCard = !this.showGreenInputLastNameOnCard;
                    } else {
                        this.showErrorInputLastNameOnCard = true;
                        this.showGreenInputLastNameOnCard = !this.showErrorInputLastNameOnCard;
                    }
                }
                if (!this.paysafeInstance?.fields?.cardNumber?.isValid() || this.paysafeInstance?.getCardBrand() === 'Diners Club' || this.paysafeInstance?.getCardBrand() === 'Discover') {
                    this.changeImpactedElement(this.cardNumberTextField, 'ds-input--success', 'ds-input--error');
                    this.paysafeInstance?.getCardBrand() === 'Diners Club' || this.paysafeInstance?.getCardBrand() === 'Discover' ? this.showCreditCardType = true : this.showCcnError = true;
                } else {
                    this.changeImpactedElement(this.cardNumberTextField, 'ds-input--error', 'ds-input--success');
                    this.showCcnError = false;
                    this.showCreditCardType = false;
                }
                if (!this.paysafeInstance?.fields?.cvv?.isValid()) {
                    this.changeImpactedElement(this.cvvTextField, 'ds-input--success', 'ds-input--error');
                    this.showCvvError = true;
                } else {
                    this.changeImpactedElement(this.cvvTextField, 'ds-input--error', 'ds-input--success');
                    this.showCvvError = false;
                }
                if (!this.paysafeInstance?.fields?.expiryDate?.isValid()) {
                    this.changeImpactedElement(this.expiryDateTextField, 'ds-input--success', 'ds-input--error');
                    this.showExpError = true;
                } else {
                    this.changeImpactedElement(this.expiryDateTextField, 'ds-input--error', 'ds-input--success');
                    this.showExpError = false;
                }
            }

            if (!this.usingPreviousCard && (!this.firstNameOnCard || !this.lastNameOnCard || this.showCreditCardType || this.showCcnError || this.showCvvError || this.showExpError)) {
                this.parent.summaryButtonState = 'disabled';
                return this.toastService.showToast('Invalid payment details!', 'Some required information is missing or incorrect. Please correct the payment details fields and try again.', 'error');
            }

            if (this.usingPreviousCard && this.selectedCard) {
                this.parent.summaryButtonState = 'processing';
                this.handleUsingSelectedCard();
                return;
            } else if (!this.selectedCard && this.usingPreviousCard) {
                this.toastService.showToast('Credit card not selected', 'Please select a credit card to proceed with your payment.', 'error');
                this.parent.summaryButtonState = 'error';
                this.loading = false;
            } else {
                this.parent.summaryButtonState = 'processing';
                if (paysafeIgnoreCc().indexOf(this.billing.country) !== -1) {
                    this.ignore3DS = true;
                }
                let accountId = paysafeUsdAccount();
                let currency = 'USD';
                let amount = this.totalPrice;
                if (this.preferredCurrency === 'CAD' || this.forceCad) {
                    accountId = paysafeCadAccount();
                    currency = 'CAD';
                    amount = this.totalPrice * this.cadRate;
                }
                this.paysafeInstance.tokenize({
                    vault: {
                        holderName: this.firstNameOnCard + ' ' + this.lastNameOnCard,
                        billingAddress: this.billing
                    },
                    threeDS: this.ignore3DS ? null : {
                        useThreeDSecureVersion2: true,
                        amount: parseInt(amount.toFixed(2).toString().replace('.', '')),
                        currency: currency,
                        accountId: accountId,
                        profile: {
                            email: this.customer.email
                        },
                        electronicDelivery: {
                            isElectronicDelivery: true,
                            email: this.customer.email
                        },
                        messageCategory: 'PAYMENT'
                    }
                }, (instance, error, result) => {
                    if (error) {
                        console.log(error);
                        this.toastService.showToast('Error', `(${error.code}) ${error.detailedMessage}`, 'error');
                        this.parent.summaryButtonState = 'error';
                        this.loading = false;
                    } else {
                        this.parent.handlePaysafeOrder(result.token);
                    }
                });
            }
        } catch (e) {
            console.log(e);
            this.parent.summaryButtonState = 'error';
            this.loading = false;
        }
    }

    changeImpactedElement(refID, removeClass, addClass) {
        removeClass = removeClass || '';
        addClass = addClass || '';
        const element = refID;
        for (const cssClass of removeClass.split(' ').filter(c => c !== '')) {
            element.classList.remove(cssClass);
        }
        for (const cssClass of addClass.split(' ').filter(c => c !== '')) {
            element.classList.add(cssClass);
        }
    }

    cardBrandRecognition() {
        this.paysafeInstance.fields.cardNumber.on('FieldValueChange', (instance) => {
            if (!instance.fields.cardNumber.isEmpty()) {
                const cardBrand = instance.getCardBrand();
                switch (cardBrand) {
                    case 'American Express':
                        this.cardSource = '/payment-methods/amex.svg';
                        break;
                    case 'MasterCard':
                        this.cardSource = '/payment-methods/mastercard.svg';
                        break;
                    case 'Visa':
                        this.cardSource = '/payment-methods/visa.svg';
                        break;
                    case 'Diners Club':
                        this.cardSource = '/payment-methods/diners.svg';
                        break;
                    case 'Discover':
                        this.cardSource = '/payment-methods/discover.svg';
                        break;
                }
            } else {
                this.cardSource = '/payment-methods/generic.svg';
                this.cardHeight = 18;
            }
        });
    }

    showBillingGreenCheckMarkChanged() {
        this.customerFullNameStyleChange();
    }

    customerFullNameStyleChange() {
        if (!this.usingPreviousCard) {
            if (this.paysafeInstance?.areAllFieldsValid() && this.firstNameOnCard && this.lastNameOnCard && this.billing.city && this.billing.country && this.billing.street && this.billing.zip && this.showBillingGreenCheckMark) {
                this.parent.summaryButtonState = 'active';
            } else {
                this.parent.summaryButtonState = 'disabled';
            }
        } else {
            this.parent.summaryButtonState = 'active';
        }
    }

    handleCardNumberValidation = (event) => {
        if (!this.paysafeInstance?.fields?.cardNumber?.isValid() || event.data.cardBrand === 'Diners Club' || event.data.cardBrand === 'Discover') {
            this.changeImpactedElement(this.cardNumberTextField, 'ds-input--success', 'ds-input--error');
            if (event.data.cardBrand === 'Diners Club' || event.data.cardBrand === 'Discover') {
                this.showCreditCardType = true;
                this.showCcnError = false;
            } else {
                this.showCreditCardType = false;
                this.showCcnError = true;
            }
        } else {
            this.changeImpactedElement(this.cardNumberTextField, 'ds-input--error', 'ds-input--success');
            this.showCcnError = false;
            this.showCreditCardType = false;
        }
        this.customerFullNameStyleChange();
    };

    handleExpiryDateValidation = () => {
        if (!this.paysafeInstance?.fields?.expiryDate?.isValid()) {
            this.changeImpactedElement(this.expiryDateTextField, 'ds-input--success', 'ds-input--error');
            this.showExpError = true;
        } else {
            this.changeImpactedElement(this.expiryDateTextField, 'ds-input--error', 'ds-input--success');
            this.showExpError = false;
        }
        this.customerFullNameStyleChange();
    };

    handleCvvValidation = () => {
        if (!this.paysafeInstance?.fields?.cvv?.isValid()) {
            this.changeImpactedElement(this.cvvTextField, 'ds-input--success', 'ds-input--error');
            this.showCvvError = true;
        } else {
            this.changeImpactedElement(this.cvvTextField, 'ds-input--error', 'ds-input--success');
            this.showCvvError = false;
        }
        this.customerFullNameStyleChange();
    };

    handleFocusEvent = (element: HTMLElement) => {
        element.classList.remove('ds-input--error', 'ds-input--success');
        this.customerFullNameStyleChange();
    };

    fieldsStyleChange() {
        this.paysafeInstance.fields.cardNumber.on('Blur', (_, event) => {
            if (!event?.data?.isEmpty) this.ccnNotFirstTimeWriting = true;
            this.handleCardNumberValidation(event);
        });
        this.paysafeInstance.fields.expiryDate.on('Blur', (_, event) => {
            if (!event?.data?.isEmpty) this.expiryDateNotFirstTimeWriting = true;
            this.handleExpiryDateValidation();
        });
        this.paysafeInstance.fields.cvv.on('Blur', (_, event) => {
            if (!event?.data?.isEmpty) this.cvvNotFirstTimeWriting = true;
            this.handleCvvValidation();
        });
        this.paysafeInstance.fields.cardNumber.on('Focus', () => {
            this.handleFocusEvent(this.cardNumberTextField);
            this.showCcnError = false;
            this.showCreditCardType = false;
        });
        this.paysafeInstance.fields.expiryDate.on('Focus', () => {
            this.handleFocusEvent(this.expiryDateTextField);
            this.showExpError = false;
            this.customerFullNameStyleChange();
        });
        this.paysafeInstance.fields.cvv.on('Focus', () => {
            this.handleFocusEvent(this.cvvTextField);
            this.showCvvError = false;
        });
        this.paysafeInstance.fields.cardNumber.on('FieldValueChange', (_, event) => {
            if (this.ccnNotFirstTimeWriting) this.handleCardNumberValidation(event);
        });
        this.paysafeInstance.fields.expiryDate.on('FieldValueChange', () => {
            if (this.expiryDateNotFirstTimeWriting) this.handleExpiryDateValidation();
        });
        this.paysafeInstance.fields.cvv.on('FieldValueChange', () => {
            if (this.cvvNotFirstTimeWriting) this.handleCvvValidation();
        });
    }

    getCardImage(type) {
        switch (type.toLowerCase()) {
            case 'vd':
            case 've':
            case 'vi':
                return '/payment-methods/visa.svg';
            case 'mc':
                return '/payment-methods/mastercard.svg';
            case 'am':
                return '/payment-methods/amex.svg';
            case 'dc':
                return '/payment-methods/diners.svg';
            case 'di':
                return '/payment-methods/discover.svg';
            default:
                return '/payment-methods/generic.svg';
        }
    }

    savedCardStyling(type) {
        switch (type.toLowerCase()) {
            case 'vi':
            case 've':
            case 'vd':
            case 'mc':
            case 'am':
            case 'dc':
            case 'di':
                return true;
            default:
                return false;
        }
    }

    handleUsingSelectedCard() {
        if (this.isCardExpired(this.selectedCard)) {
            this.toastService.showToast('Card Expired!', 'Please update your card expiration.', 'error');
            this.parent.summaryButtonState = 'error';
            this.loading = false;
        } else {
            if (paysafeIgnoreCc().indexOf(this.billing.country) === -1) {
                let accountId;
                let currency;
                let amount;
                if (this.preferredCurrency === 'CAD' || this.forceCad) {
                    accountId = paysafeCadAccount();
                    currency = 'CAD';
                    amount = this.totalPrice * this.cadRate;
                } else {
                    accountId = paysafeUsdAccount();
                    currency = 'USD';
                    amount = this.totalPrice;
                }
                window.paysafe.threedsecure.start(paysafeApiKey(), {
                    environment: paysafeEnvironment(),
                    accountId: accountId,
                    card: {
                        cardBin: this.selectedCard.cardBin
                    }
                }, async(deviceFingerprintingId, error) => {
                    if (error) {
                        console.log(error);
                        this.toastService.showToast('Error', `(${error.code}) ${error.detailedMessage}`, 'error');
                        this.parent.summaryButtonState = 'error';
                        this.loading = false;
                    } else {
                        const authenticationRequest = {
                            deviceFingerprintingId: deviceFingerprintingId,
                            merchantRefNum: `${websiteShortCode()}-${this.preferredCurrency}-${this.customer.id}-3ds-${Date.now().toString(36) + Math.random().toString(36).substring(2)}`,
                            amount: parseInt(amount.toFixed(2).toString().replace('.', '')),
                            currency: currency,
                            card: {
                                paymentToken: this.selectedCard.paymentToken
                            }
                        };
                        const authenticationResponse = await this.customerService.processPaysafe3DS(authenticationRequest);
                        if (authenticationResponse?.sdkChallengePayload) {
                            window.paysafe.threedsecure.challenge(paysafeApiKey(), {
                                environment: paysafeEnvironment(),
                                sdkChallengePayload: authenticationResponse.sdkChallengePayload
                            }, (authenticationId, authenticationError) => {
                                if (authenticationError) {
                                    console.log(authenticationError);
                                    this.toastService.showToast('Error', `(${authenticationError.code}) ${authenticationError.detailedMessage}`, 'error');
                                    this.parent.summaryButtonState = 'error';
                                    this.loading = false;
                                } else {
                                    this.selectedCard.authenticationId = authenticationId;
                                    this.parent.handlePaysafeOrder(null, this.selectedCard);
                                }
                            });
                        } else {
                            this.parent.handlePaysafeOrder(null, this.selectedCard);
                        }
                    }
                });
            } else {
                this.parent.handlePaysafeOrder(null, this.selectedCard);
            }
        }
    }

    async removeCreditCard(card) {
        if (window.confirm('Are you sure you would like to remove this card?')) {
            const response = await this.customerService.deletePaysafeCard(card.id);
            if (response) {
                const cardIndex = this.availableCards.findIndex(x => x.id === card.id);
                if (cardIndex > -1) {
                    this.availableCards.splice(cardIndex, 1);
                }
                if (this.availableCards?.length === 0) {
                    this.parent.shouldShowBlueLine = false;
                    this.usingPreviousCard = false;
                    this.showBillingGreenCheckMark = false;
                    this.showBillingErrorCheckMark = false;
                    this.emptySavedCards = true;
                    this.initializeCreditCardFields();
                } else {
                    this.selectedCard = this.availableCards[0];
                }
                return this.toastService.showToast('Success!', 'Card removed.', 'success');
            }
        }
    }

    getCardAddress(card) {
        const billingInfo = this.paysafeVaultedProfile?.addresses?.find(x => x.id === card.billingAddressId);
        if (billingInfo) {
            return `${billingInfo.street}, ${billingInfo.city}, ${billingInfo.state !== null ? billingInfo.state + ',' : ''} ${billingInfo.zip}, ${billingInfo.country.toUpperCase()}`;
        }
    }

    async updateCardExpiry() {
        try {
            ValidationRules
                .ensure('firstNameExpiry').required().withMessage('First name required.')
                .ensure('lastNameExpiry').required().withMessage('Last name required.')
                .ensure('expiryDate').required().matches(/^(0[1-9]|1[0-2]) \/ ([0-9]{2})$/)
                .satisfies((dateExp) => {
                    if (dateExp?.length === 7 && ((parseInt(dateExp.slice(-2)) < this.currentYear) || (parseInt(dateExp.substring(0, 2)) < this.currentMonth && parseInt(dateExp.slice(-2)) === this.currentYear))) {
                        return false;
                    }
                    return true;
                }).withMessage('Invalid Date Expiry.')
                .on(this);
            const result = await this.validator.validate();
            if (result.valid) {
                this.readyingForm = true;
                const response = await this.customerService.updatePaysafeCard(this.firstNameExpiry, this.lastNameExpiry, this.selectedCard.id, this.expiryDate);
                if (response) {
                    this.availableCards = [];
                    this.paysafeVaultedProfile = await this.customerService.getPaysafeVaultProfile();
                    if (this.paysafeVaultedProfile?.cards?.length > 0) {
                        this.usingPreviousCard = true;
                        for (const paymentSource of this.paysafeVaultedProfile.cards) {
                            paymentSource.cardExpiry.month.toString().length < 2 ? paymentSource.cardExpiry.month = '0' + paymentSource.cardExpiry.month.toString() : paymentSource.cardExpiry.month;
                            paymentSource.cardExpiry.year = paymentSource.cardExpiry.year.toString().substring(2);
                            this.availableCards.push(paymentSource);
                        }
                        if (this.availableCards?.length > 0) {
                            const paysafeVaultLastUsedCard = await this.customerService.getPaysafeVaultLastUsedCard();
                            if (paysafeVaultLastUsedCard) {
                                this.selectedCard = this.availableCards.find(c => paysafeVaultLastUsedCard.lastUsedCard?.lastDigits === c.lastDigits &&
                                    paysafeVaultLastUsedCard.lastUsedCard?.type === c.cardType);
                                if (!this.selectedCard) {
                                    this.selectedCard = this.availableCards[0];
                                }
                            } else {
                                this.selectedCard = this.availableCards[0];
                            }
                        }
                        this.setBillingForCard();
                        this.parent.shouldShowBlueLine = true;
                        this.parent.summaryButtonState = 'active';
                    } else {
                        this.billing.zip = this.customer?.zip;
                        this.billing.street = this.customer?.address;
                        this.billing.country = this.customer?.country?.toUpperCase();
                        this.billing.state = this.customer?.state;
                        this.billing.city = this.customer?.city;
                    }
                    this.updatingCardExpiry = false;
                    this.toastService.showToast('Success!', 'Credit card details updated.', 'success');
                }
            } else {
                this.showGreenCheckMarkFirstNameExpiry = result.results.filter(r => r.propertyName === 'firstNameExpiry').every(r => r.valid);
                this.showErrorCheckMarkFirstNameExpiry = !this.showGreenCheckMarkFirstNameExpiry;
                this.showGreenCheckMarkLastNameExpiry = result.results.filter(r => r.propertyName === 'lastNameExpiry').every(r => r.valid);
                this.showErrorCheckMarkLastNameExpiry = !this.showGreenCheckMarkLastNameExpiry;
                this.showGreenCheckMarkDateExpiry = result.results.filter(r => r.propertyName === 'expiryDate').every(r => r.valid);
                this.showErrorCheckMarkDateExpiry = !this.showGreenCheckMarkDateExpiry;
                this.toastService.showToast('Error', 'Some required information is missing or incorrect. Please correct the address fields and try again.', 'error');
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.readyingForm = false;
        }
    }

    async checkFirstNameExpiryOnKeyPress() {
        ValidationRules.ensure('firstNameExpiry').required().on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkFirstNameExpiry = rules.valid;
        this.showErrorCheckMarkFirstNameExpiry = !rules.valid;
    }

    async checkLastNameExpiryOnKeyPress() {
        ValidationRules.ensure('lastNameExpiry').required().on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkLastNameExpiry = rules.valid;
        this.showErrorCheckMarkLastNameExpiry = !rules.valid;
    }

    async checkExpiryDateOnKeyPress(event) {
        ValidationRules
            .ensure('expiryDate').required().matches(/^(0[1-9]|1[0-2]) \/ ([0-9]{2})$/)
            .satisfies((dateExp) => {
                if (dateExp?.length === 7 && ((parseInt(dateExp.slice(-2)) < this.currentYear) || (parseInt(dateExp.substring(0, 2)) < this.currentMonth && parseInt(dateExp.slice(-2)) === this.currentYear))) {
                    return false;
                }
                return true;
            }).withMessage('Invalid Date Expiry.')
            .on(this);
        const rules = await this.validator.validate();
        this.showGreenCheckMarkDateExpiry = rules.valid;
        this.showErrorCheckMarkDateExpiry = !rules.valid;
        if (this.cardExpiryDate.value.length === 2 && !(event.keyCode === 46 || event.keyCode === 8)) {
            this.cardExpiryDate.value += ' / ';
        } else if (this.cardExpiryDate.value.length === 3) {
            this.replaceValue = this.cardExpiryDate.value.slice(-1);
            this.cardExpiryDate.value = this.cardExpiryDate.value.substring(0, 2);
            this.cardExpiryDate.value += ' / ';
            this.cardExpiryDate.value += this.replaceValue;
        } else if ((this.cardExpiryDate.value.length === 5 || this.cardExpiryDate.value.length === 4) && (event.keyCode === 46 || event.keyCode === 8)) {
            this.cardExpiryDate.value = this.cardExpiryDate.value.slice(0, 2);
        }
    }
}
