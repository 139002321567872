import './apple-pay-checkout-form.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { CurrencyService } from 'services/currency-service';
import { CustomerService } from 'services/customer-service';
import { RateLimiter } from 'limiter';
import { checkoutPublicKey, applePayMerchantId, vgsApplePayInboundRouteId, vgsEnv, vgsVaultId, debug } from 'environment';
import { ToastService } from 'services/toast-service';
import { cardTypesFor3DSTrigger, automaticPaymentMethodsWithout3DS } from 'resources/constants';
import { Helper } from 'resources/extensions/helper';
import { VGSDebitCreditCardService } from 'services/vgs-service';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';

@autoinject()
export class ApplePayCheckoutForm {
    constructor(
        private eventAggregator: EventAggregator,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private customerService: CustomerService,
        private currencyService: CurrencyService,
        private toastService: ToastService,
        private helper: Helper,
        private vgsDebitCreditCardService: VGSDebitCreditCardService,
        private camelCaseValueConverter: CamelCaseValueConverter
    ) {
        this.rateLimiter = new RateLimiter({ tokensPerInterval: 2, interval: 3600000, fireImmediately: true });
    }

    @bindable totalPrice;
    @bindable preferredCurrency;
    @bindable billing;
    @bindable user;
    challengeIframe;
    applePayInitializeCount = 0;
    appleInboundRoute = `https://${vgsVaultId()}-${vgsApplePayInboundRouteId()}.${vgsEnv()}.verygoodproxy.com/post`;
    cardTypesFor3DSTrigger = cardTypesFor3DSTrigger();
    automaticPaymentMethodsWithout3DS = automaticPaymentMethodsWithout3DS();
    rateLimiter;
    parent;
    width;
    sizeChanged;
    remainingRequests;
    deviceSessionId;
    dialogModule;
    longPollTimeout;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        await this.configureApplePay();
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
        });
    }

    async configureApplePay() {
        if (!window.window.ApplePaySession) {
            this.toastService.showToast('Info', 'Apple Pay is not available for your device, please try using iOS device.', 'info');
            return;
        }

        while (this.applePayInitializeCount < 8) {
            this.applePayInitializeCount++;

            const promise = await window.ApplePaySession.canMakePaymentsWithActiveCard(applePayMerchantId());
            if (!promise) {
                if (this.applePayInitializeCount === 8) this.toastService.showToast('Error', 'Something went wrong. Please validate whether you have linked an active card to the Apple Wallet and try again.', 'error');
                await new Promise(resolve => setTimeout(resolve, 1000));
                continue;
            }

            const applePayButton = document.querySelector('.ds-apple-pay-box');
            if (!applePayButton) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                continue;
            }
            applePayButton.innerHTML = '<apple-pay-button buttonstyle="black" type="buy" locale="en-US">';
            applePayButton.querySelector('apple-pay-button')?.addEventListener('click', async() => {
                if (this.parent?.summaryButtonState === 'disabled') return;

                this.remainingRequests = await this.rateLimiter.removeTokens(1);

                if (this.remainingRequests < 0 && !debug()) {
                    return this.toastService.showToast('Error', 'You’ve made a payment attempt too many times recently. Try again later.', 'error');
                }

                if (!this.parent.validBilling()) {
                    this.toastService.showToast('Error', 'Please enter a valid billing address to proceed with your payment.', 'error');
                    this.parent.paymentProcessing = false;
                    this.parent.summaryButtonState = 'valid';
                    return;
                }

                const rate = await this.currencyService.getStoredCurrencyRates(this.preferredCurrency);
                const request: ApplePayJS.ApplePayPaymentRequest = {
                    countryCode: this.billing.country ?? 'US',
                    currencyCode: this.preferredCurrency,
                    supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'bancomat', 'bancontact', 'cartesBancaires', 'chinaUnionPay',
                        'dankort', 'eftpos', 'electron', 'elo', 'girocard', 'interac', 'jcb', 'mada', 'maestro', 'mir', 'privateLabel', 'vPay'],
                    merchantCapabilities: ['supports3DS', 'supportsEMV', 'supportsCredit', 'supportsDebit'],
                    requiredBillingContactFields: ['postalAddress', 'name'],
                    requiredShippingContactFields: ['postalAddress', 'email', 'phone'],
                    total: {
                        label: 'DivicaSales',
                        type: 'final',
                        amount: ((this.totalPrice * rate).toFixed(2)).toString()
                    }
                };

                const session = new window.ApplePaySession(2, request);
                session.begin();

                session.onvalidatemerchant = async(event) => {
                    const validationUrl = event.validationURL;
                    const response = await this.customerService.validateApplePaySession(validationUrl);
                    if (!response) {
                        this.toastService.showToast('Error', 'We can’t process your order right now, please try again later.', 'error');
                        return;
                    }
                    session.completeMerchantValidation(response);
                };

                session.onpaymentmethodselected = () => {
                    const total: ApplePayJS.ApplePayLineItem = {
                        label: 'DivicaSales',
                        type: 'final',
                        amount: ((this.totalPrice * rate).toFixed(2)).toString()
                    };

                    const update = {
                        newTotal: total
                    };
                    session.completePaymentMethodSelection(update);
                };

                session.onpaymentauthorized = async(event) => {
                    if (event) {
                        session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                        try {
                            this.deviceSessionId = await this.handleCheckoutFraudDetection();
                        } catch (e) {
                            console.log(e);
                        }

                        try {
                            if (this.parent.vgsData) {
                                this.parent.handleApplePayDirectOrder(null, this.deviceSessionId);
                                return;
                            }

                            const response = await fetch(this.appleInboundRoute, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(event.payment.token)
                            });

                            if (response.ok) {
                                const json = await response.json();
                                const finalizedPaymentData = this.camelCaseValueConverter.toView(json);
                                this.parent.vgsData = {
                                    cardNumber: finalizedPaymentData.applePayToken.applicationPrimaryAccountNumber,
                                    cardExp: `${finalizedPaymentData.applePayToken.applicationExpirationDate.substring(2, 4)} / ${finalizedPaymentData.applePayToken.applicationExpirationDate.substring(0, 2)}`,
                                    cardType: event.payment.token.paymentMethod.network.toLowerCase(),
                                    lastFour: event.payment.token.paymentMethod.displayName.slice(-4)
                                };

                                this.parent.forceVerification = true;

                                if (this.helper.excludeAll('google-pay-direct', this.automaticPaymentMethodsWithout3DS)) {
                                    let threeDSResponse;
                                    if (this.helper.includesSome(event.payment.token.paymentMethod.network.toLowerCase(), this.cardTypesFor3DSTrigger)) {
                                        const threeDSRequest = {
                                            amount: parseFloat(this.parent.totalPrice.toFixed(2)),
                                            pan: finalizedPaymentData.applePayToken.applicationPrimaryAccountNumber,
                                            year: finalizedPaymentData.applePayToken.applicationExpirationDate.substring(0, 2),
                                            month: finalizedPaymentData.applePayToken.applicationExpirationDate.substring(2, 4),
                                            lastFour: event.payment.token.paymentMethod.displayName.slice(-4),
                                            cardType: event.payment.token.paymentMethod.network.toLowerCase(),
                                            browser: this.helper.addBrowserInfoData()
                                        };

                                        threeDSResponse = await this.vgsDebitCreditCardService.handle3dsResult(threeDSRequest);
                                    }

                                    let threeDsResult;

                                    if (threeDSResponse?.methodURL && threeDSResponse?.threeDSMethodData) {
                                        await this.vgsDebitCreditCardService.handle3dsFingerprint(threeDSResponse.methodURL, threeDSResponse.threeDSMethodData);
                                    }

                                    if (threeDSResponse?.transactionId) {
                                        threeDsResult = await this.vgsDebitCreditCardService.get3dsResult(threeDSResponse.transactionId);
                                    }

                                    if (threeDsResult?.status === 'C') {
                                        this.challengeIframe = await this.vgsDebitCreditCardService.handle3dsChallenge(threeDsResult.acsURL, threeDsResult.creq);
                                        await this.threeDsResultLongPoll(threeDSResponse.transactionId, threeDsResult.updateDate);
                                        return;
                                    }
                                    this.parent.threeDsData = threeDsResult;
                                }

                                this.parent.handleApplePayDirectOrder(null, this.deviceSessionId);
                            } else {
                                const innerResponse = await response.text();
                                this.toastService.showToast('Error', innerResponse, 'error');
                                this.parent.summaryButtonState = 'active';
                            }
                        } catch (error) {
                            console.log(error);
                            this.parent.summaryButtonState = 'active';
                        }
                    } else {
                        session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                        this.toastService.showToast('Error', 'Your payment has been declined. Please try again, or contact support for assistance.', 'error');
                    }
                };
            });

            break;
        }
    }

    async handleCheckoutFraudDetection() {
        const risk = window.Risk.init(checkoutPublicKey());
        return await risk.publishRiskData();
    }

    challengeIframeChanged() {
        this.dialogModule?.dialog?.open();
    }

    async threeDsResultLongPoll(transactionId, initialDate, fromPayButton = false) {
        if (fromPayButton) {
            this.dialogModule?.dialog?.open();
            this.parent.summaryButtonState = 'processing';
        }

        const result = await this.vgsDebitCreditCardService.get3dsResult(transactionId);

        if (result.updateDate !== initialDate && result.status !== 'C') {
            this.dialogModule?.dialog?.close();
            this.parent.threeDsData = result;
            this.parent.handleApplePayDirectOrder(null, this.deviceSessionId);
        } else {
            if (!this.dialogModule?.dialog.foundation.dialogOpen && !fromPayButton) {
                this.parent.summaryButtonFunction = () => {
                    this.threeDsResultLongPoll(transactionId, initialDate, true);
                };
                return;
            }

            this.longPollTimeout = setTimeout(() => {
                this.threeDsResultLongPoll(transactionId, initialDate);
            }, 4000);
        }
    }
}
